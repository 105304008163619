import type { AdItem } from '@/components/ad-banner/ad-item';
import type { BannerItem } from '@/components/banner/banner-item';
import {
  CommonEmailIcon,
  CompanyDiscordIcon,
  CompanyMediumTextIcon,
  CompanyTelegramIcon,
  CompanyTwitterIcon,
} from '@burve-front/react-ui';

export const DEV = import.meta.env.DEV;
export const PROD = import.meta.env.PROD;
export const PREVIEW = import.meta.env.MODE === 'preview';
export const TEST = DEV || PREVIEW;

export const APP_NAME = '@burve-front/react-app';

export const APP_THEME = 'dark';
export const APP_THEMES = ['system', 'dark', 'light'];

export const APP_I18N = {
  defaultLocale: 'en',
  locales: [
    { name: 'English', lang: 'en' },
    { name: '简体中文', lang: 'zh' },
  ],
};

export const APP_DIR = 'ltr';

// ipfs conf
export const IPFS_CONF = {
  endpoint: '',
  gateway: 'https://ipfs.io/ipfs/{cid}',
  auth: '',
};

export const UPDATE_STEP_BLOCKNUM = 10;

export const CLOUDFLARE_ANALYTICS_TOKEN = import.meta.env.VITE_ANALYTICS_TOKEN;

export const banners: BannerItem[] = [
  {
    id: 1,
    img: '/imgs/banners/banner-lol.webp?1721022000',
    href: 'https://burve.medium.com/hodl-vs-lol-introducing-burve-protocols-dual-launch-modes-90c43ed966c3',
  },
  {
    id: 2,
    img: '/imgs/banners/banner-acceleration-fund.webp?1726666000',
    href: 'https://burve.medium.com/burve-x-bnb-chain-present-meme-hero-incubation-camp-your-path-to-a-100-000-acceleration-fund-9da91bb5f7ba',
  },
];

export const ad: AdItem[] = [
  {
    id: 1,
    text: 'xxxxx campaign is in full swing',
    href: 'https://burve.medium.com/hodl-vs-lol-introducing-burve-protocols-dual-launch-modes-90c43ed966c3',
  },
  {
    id: 2,
    img: '/imgs/banners/banner-acceleration-fund.webp?1726666000',
    href: 'https://burve.medium.com/burve-x-bnb-chain-present-meme-hero-incubation-camp-your-path-to-a-100-000-acceleration-fund-9da91bb5f7ba',
  },
];

export const docsInfo = [
  {
    label: 'whitepaper',
    path: 'https://burve.io/papers/Whitepaper_BurveLabs.pdf?171151932000',
  },
  // {
  //   label: 'litepaper',
  //   path: 'https://burve.io/Litepaper_BurveProtocol_V1.1.pdf?171151932000',
  // },
  {
    label: 'docs',
    path: 'https://docs.burve.io/',
  },
  {
    label: 'audit',
    path: 'https://docs.burve.io/security-audit',
  },
];
export const media = [
  {
    icon: CompanyTwitterIcon,
    path: 'https://twitter.com/BurveProtocol',
  },
  {
    icon: CompanyMediumTextIcon,
    path: 'https://burve.medium.com',
  },
  {
    icon: CommonEmailIcon,
    path: 'mailto:support@burve.io',
  },
  {
    icon: CompanyDiscordIcon,
    path: 'https://discord.gg/fypW4zAqMB',
  },
  // {
  //   icon: Images.SnapshotIcon,
  //   path: 'https://snapshot.org/#/burve.eth',
  // },
  {
    icon: CompanyTelegramIcon,
    path: ' https://t.me/BurveOfficial',
  },
];

import {
  Button,
  CommonSearchIcon,
  Dialog,
  DialogContent,
  DialogTrigger,
  Input,
  ProjectAllIcon,
  ProjectFairIcon,
  ProjectMemeIcon,
  ProjectUpcomingIcon,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@burve-front/react-ui';
import { useState } from 'react';
import { AllContent } from './all-content';
import { HodlContent } from './hodl-content';
import { LoLContent } from './lol-content';
import { UpcomingContent } from './upcoming-content';

const tabs = [
  {
    value: 'all',
    label: 'All',
    icon: <ProjectAllIcon className="mr-1 size-4 fill-current" />,
    content: <AllContent />,
  },
  {
    value: 'hodl',
    label: 'Fair Launch (HODL)',
    icon: <ProjectFairIcon className="mr-1 size-4 fill-current" />,
    content: <HodlContent />,
  },
  {
    value: 'meme',
    label: 'Meme (LOL)',
    icon: <ProjectMemeIcon className="mr-1 size-4 fill-current" />,
    content: <LoLContent />,
  },
  {
    value: 'upcoming',
    label: 'Upcoming Listing',
    icon: <ProjectUpcomingIcon className="mr-1 size-4 fill-current" />,
    content: <UpcomingContent />,
  },
];

export const SearchToken = () => {
  const [select, setSelect] = useState(tabs[0].value);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="size-8 p-0">
          <CommonSearchIcon className="size-3.5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[794px] max-w-[640px] w-full p-0" closeClassName="top-3">
        <div className="h-8 w-full flex items-center border-b p-5">
          <CommonSearchIcon className="mr-2 size-3.5" />
          <Input
            variant="ghost"
            className="size-full h-8 w-full cursor-pointer border-none pr-6 text-xs"
            placeholder="Search project name/token symbol/contract address"
          />
        </div>
        <Tabs defaultValue={select} onValueChange={setSelect}>
          <TabsList variant="primary" className="mx-5 mt-5 overflow-auto space-x-6">
            {tabs.map((item) => (
              <TabsTrigger key={`tabs-trigger-${item.value}`} variant="primary" value={item.value}>
                {item.icon}
                {item.label}
              </TabsTrigger>
            ))}
          </TabsList>
          {tabs.map((item) => (
            <TabsContent
              key={`tabs-content-${item.value}`}
              value={item.value}
              className="mx-3 mb-3 mt-0 max-h-[584px] overflow-auto">
              {item.content}
            </TabsContent>
          ))}
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

import type { NProgress, NProgressOptions } from 'nprogress';
import nprogress from 'nprogress';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-use';

export const useNProgress = (options: Partial<NProgressOptions> = {}): NProgress => {
  const location = useLocation();
  const ref = useRef<NProgress>();
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (ref.current) ref.current.done();
    return () => {
      if (ref.current) ref.current.start();
    };
  }, [location]);
  useEffect(() => {
    if (!ref.current) {
      ref.current = nprogress.configure(options);
    }
    return () => {
      if (ref.current) ref.current.remove();
    };
  }, [options]);
  return nprogress;
};

export default useNProgress;

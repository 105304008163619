import { TEST } from '@/confs/app';
import { SOLANA_ADAPTERS, SOLANA_DEFAULT_CHAIN } from '@/confs/solana';
import useChain from '@/hooks/use-chain';
import useModalStore from '@/store/modal-store';
import { AddressView, Avatar, Badge, Button, CommonPowerIcon, SpanLoading, UserAvatar } from '@burve-front/react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SolansAdapters = () => {
  const { t } = useTranslation();
  const isConnectModalOpen = useModalStore((state) => state.isConnectModalOpen);
  const setIsConnectModalOpen = useModalStore((state) => state.setIsConnectModalOpen);
  const { chain, switchChain } = useChain();
  const [pending, setPending] = useState<string>();
  const [error, setError] = useState<string>();
  const { publicKey, connect, connecting, connected, select, wallets, wallet, disconnect } = useWallet();
  const address = useMemo(() => publicKey?.toString(), [publicKey]);
  const supplyWallets = useMemo(
    () => wallets.filter((item) => SOLANA_ADAPTERS.some((adapter) => adapter.name === item.adapter.name)),
    [wallets],
  );
  const connectWallet = useCallback(async () => {
    if (!wallet || connected || wallet.readyState !== 'Installed') return;
    setError(undefined);
    setPending(wallet.adapter.name);
    try {
      if (!chain?.custom?.isSolana && !chain?.custom?.network) await switchChain(SOLANA_DEFAULT_CHAIN.name);
      await connect();
      setIsConnectModalOpen(false);
    } catch (error: any) {
      if (TEST) console.error(error);
      setError(error?.message ?? error);
    }
    setPending(undefined);
  }, [chain?.custom?.isSolana, chain?.custom?.network, connect, connected, setIsConnectModalOpen, switchChain, wallet]);

  useEffect(() => {
    connectWallet();
  }, [connectWallet]);

  useEffect(() => {
    if (!isConnectModalOpen) setError(undefined);
  }, [isConnectModalOpen]);

  if (connected && wallet) {
    return (
      <div className="flex flex-col space-y-2">
        <div className="h-10 flex items-center justify-center rounded-md text-sm space-x-1">
          <UserAvatar address={address} className="size-5" />
          <AddressView address={address} showTip={false} showCopy={false} />
          <span className="">({wallet?.adapter.name})</span>
          <Button variant="outline" size="icon" className="size-8 cursor-pointer" onClick={() => disconnect()}>
            <CommonPowerIcon className="size-3" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-3">
      {supplyWallets.map((item) => (
        <Button
          key={`solans-wallet-${item.adapter.name}`}
          variant="secondary"
          size="lg"
          disabled={connecting}
          onClick={() => select(item.adapter.name)}
          className="w-full justify-start px-4 space-x-2">
          <Avatar avatar={item.adapter.icon} />
          <span className="text-sm font-bold">{item.adapter.name}</span>
          <span className="inline-flex items-center">
            {item.readyState === 'Installed' && (
              <Badge variant="outline" className="border-foreground px-1 py-0 text-[10px] opacity-60">
                {t('ready')}
              </Badge>
            )}
          </span>
          {connecting && pending === item.adapter.name && <SpanLoading />}
        </Button>
      ))}
      {error && <p className="text-center text-error">{error}</p>}
    </div>
  );
};

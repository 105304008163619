import { EVM_CHAINS, EVM_CONNECTORS, EVM_DEFAULT_CHAIN } from '@/confs/evm';
import { http, type Transport } from 'viem';
import { createConfig } from 'wagmi';

export const wagmiConf = createConfig({
  chains: [EVM_DEFAULT_CHAIN, ...EVM_CHAINS.filter((item) => item.id !== EVM_DEFAULT_CHAIN.id)],
  connectors: EVM_CONNECTORS,
  syncConnectedChain: true,
  multiInjectedProviderDiscovery: false,
  batch: { multicall: true },
  // cacheTime: 1 * 60 * 1e3,
  transports: EVM_CHAINS.reduce(
    (prev, current) => {
      prev[current.id] = http();
      return prev;
    },
    {} as Record<number, Transport>,
  ),
});

import { tokens } from '@/apis/mocks';
import { candlestickData, histogramData } from '@/apis/mocks/chart';
import { Swap } from '@burve-front/react-swap';
import {
  AddressView,
  Badge,
  Button,
  CompanyDiscordIcon,
  CompanyTelegramIcon,
  CompanyTwitterIcon,
  DivideVertical,
  KLineChart,
  LargeSmallAvatar,
  NumberView,
  PairAvatar,
  ProjectAboutIcon,
  ProjectAddWalletIcon,
  ProjectArrowLeftIcon,
  ProjectBankIcon,
  ProjectCoinsIcon,
  ProjectScanIcon,
  ProjectShareIcon,
  ProjectStatsIcon,
  ProjectTransactionIcon,
  ProjectWebsiteIcon,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@burve-front/react-ui';
import { useLocation, useParams } from 'wouter';
import { TheErrorPage } from '../error';
import { Transaction } from './components/transaction';

export const TheTokenDetailPage = () => {
  const { address } = useParams();
  const token = tokens.find((token) => token.address === address);
  const [, navigate] = useLocation();
  const handleBack = () => {
    if (history.length > 1) {
      history.go(-1);
    } else {
      navigate('/');
    }
  };
  if (!token) {
    return <TheErrorPage />;
  }
  return (
    <div className="mx-auto max-w-[1100px] w-full pb-3.5 pt-4">
      <Button
        variant="link"
        onClick={() => handleBack}
        className="cursor-pointer p-0 text-sm text-content underline-transparent hover:-translate-x-1">
        <ProjectArrowLeftIcon className="mr-1 h-2 w-2.5 fill-content-1" />
        Explore
      </Button>
      <div className="flex justify-between space-x-6">
        {/* left */}
        <div className="mt-5 flex-1 flex-shrink-0">
          {/* avatar */}
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center justify-between space-x-1">
              <LargeSmallAvatar
                large={token.logo}
                small={token.anchor.logo}
                className="w-14"
                largeClassName="size-10"
                smallClassName="size-8"
              />
              <dl>
                <dt className="flex text-5 space-x-2">
                  <div className="max-w-[270px] truncate whitespace-nowrap">{token.symbol}</div>
                  <span>/</span>
                  <span>{token.anchor.symbol}</span>
                </dt>
                <dd className="text-sm text-content-2">{token.name}</dd>
              </dl>
            </div>
            <div className="flex items-center space-x-2">
              <Button size="icon" className="bg-opacity-30">
                <ProjectScanIcon />
              </Button>
              <Button size="icon" className="bg-opacity-30">
                <ProjectAddWalletIcon className="fill-warn" />
              </Button>
              <Button size="icon" className="bg-opacity-30 text-xs">
                <ProjectShareIcon className="mr-1 size-2.5" />
                share
              </Button>
            </div>
          </div>
          {/* price */}
          <div className="mt-5 w-full flex justify-between">
            <div className="space-x-2">
              <span className="text-6 font-500">0.80322</span> <span className="text-sm">/ $0.67</span>
              <Badge className="text-success">+39.36% (24h)</Badge>
            </div>
            <div className="text-sm text-content-1">
              <div>Buy tax rate: 10%</div>
              <div>Sell tax rate: 20%</div>
            </div>
          </div>
          {/* chart */}
          <div className="mt-4">
            <KLineChart
              histogramSeries={{ data: histogramData }}
              candlestickSeries={{ data: candlestickData }}
              className="h-[720px] w-full"
            />
          </div>
        </div>
        {/* right */}
        <div className="w-[440px] space-y-[30px]">
          <div className="border-gradient-card w-full bg-[rgba(49,14,56,0.2)] p-4 text-xs space-y-2">
            <div className="flex items-center justify-between">
              <span className="inline-flex items-center">
                <ProjectBankIcon className="mr-2 size-4" />
                Token Contract
              </span>
              <AddressView
                address={token.address}
                showCopy
                className="h-6 rounded-[4px] bg-[#16162E]/30 px-2 text-content-1"
              />
            </div>
            <div className="flex items-center justify-between">
              <span className="inline-flex items-center">
                <ProjectCoinsIcon className="mr-2 size-4" />
                Treasury Address
              </span>
              <AddressView
                address={token.address}
                showCopy
                className="h-6 rounded-[4px] bg-[#16162E]/30 px-2 text-content-1"
              />
            </div>
          </div>
          <div>
            <h3 className="mb-4 text-6 font-medium">Links</h3>
            <div className="flex items-center justify-between text-xs">
              <a href="" target="_blank" className="h-6 w-25 flex items-center rounded-[4px] bg-[#16162E]/30 px-[17px]">
                <ProjectWebsiteIcon className="mr-1 size-4 fill-content-1" />
                Website
              </a>
              <a href="" target="_blank" className="h-6 w-25 flex items-center rounded-[4px] bg-[#16162E]/30 px-[17px]">
                <CompanyTwitterIcon className="mr-1 size-4 fill-content-1" />
                Twitter
              </a>
              <a href="" target="_blank" className="h-6 w-25 flex items-center rounded-[4px] bg-[#16162E]/30 px-[17px]">
                <CompanyDiscordIcon className="mr-1 size-4 fill-content-1" />
                Discord
              </a>
              <a href="" target="_blank" className="h-6 w-25 flex items-center rounded-[4px] bg-[#16162E]/30 px-[17px]">
                <CompanyTelegramIcon className="mr-1 size-4 fill-content-1" />
                Telegram
              </a>
            </div>
          </div>
          <div>
            <h3 className="mb-4 text-6 font-medium">Pools</h3>
            <div className="border-gradient-card bg-[linear-gradient(180deg,#C09BED_1%, rgba(73,65,124,0)_57%)] w-full px-4 divide-y divide-dashed">
              <div className="flex items-center justify-between py-3">
                <div className="flex items-center justify-between space-x-2">
                  <PairAvatar one="/imgs/tokens/cirus.png" two="/imgs/tokens/usdc.png" />
                  <span>DAISY - USDT</span>
                  <Badge variant="primary500">3%</Badge>
                </div>
                <span className="text-content-1">TVL: $123,456.00</span>
              </div>
              <div className="flex items-center justify-between py-3">
                <div className="flex items-center justify-between space-x-2">
                  <PairAvatar one="/imgs/tokens/cirus.png" two="/imgs/tokens/usdc.png" />
                  <span>DAISY - USDT</span>
                  <Badge variant="primary500">3%</Badge>
                </div>
                <span className="text-content-1">TVL: $123,456.00</span>
              </div>
              <div className="flex items-center justify-between py-3">
                <div className="flex items-center justify-between space-x-2">
                  <PairAvatar one="/imgs/tokens/cirus.png" two="/imgs/tokens/usdc.png" />
                  <span>DAISY - USDT</span>
                  <Badge variant="primary500">3%</Badge>
                </div>
                <span className="text-content-1">TVL: $123,456.00</span>
              </div>
            </div>
          </div>
          <Swap />
        </div>
      </div>
      {/* metadata */}
      <div className="mt-10 w-full">
        <Tabs defaultValue="about">
          <TabsList variant="primary" className="mb-2 space-x-3">
            <TabsTrigger variant="primary" value="about">
              <ProjectAboutIcon className="mr-2 size-4" />
              About
            </TabsTrigger>
            <TabsTrigger variant="primary" value="transaction">
              <ProjectTransactionIcon className="mr-2 size-4" />
              Transaction
            </TabsTrigger>
            <TabsTrigger variant="primary" value="stats">
              <ProjectStatsIcon className="mr-2 size-4" />
              Stats
            </TabsTrigger>
          </TabsList>
          <TabsContent value="about">
            <div className="mt-5 text-content-1">
              <h3 className="mb-3">Description</h3>
              <p className="h-[110px] w-full truncate whitespace-pre-wrap text-sm text-content-2">
                DAISY Uniswap is a decentralized exchange protocol built on the Ethereum blockchain, and it is one of the
                pioneering projects in the Web3 ecosystem. It enables users to trade cryptocurrencies directly from their
                wallets, without the need for intermediaries such as centralized exchanges. Uniswap operates on an automated
                market maker (AMM) model, which means that it uses smart contracts to facilitate trading and liquidity
                provision. Instead of relying on order books and matching buyers with sellers, Uniswap relies on liquidity
                pools. These pools are composed of users&apos; funds and provide liquidity for trading. Users can contribute
                to these pools by depositing an equal value of two different tokens, forming a trading pair.
              </p>
              <p className="mt-5">Launched on Jan 18, 2023</p>
            </div>
          </TabsContent>
          <TabsContent value="transaction">
            <Transaction token={token} />
          </TabsContent>
          <TabsContent value="stats">
            <div className="border-gradient-stats h-25 w-full flex items-center justify-between border rounded-lg py-4">
              <dl className="px-4">
                <dt className="text-content-1">Circulating Supply</dt>
                <dd className="text-8 font-medium">
                  <NumberView number={1000000} after={token.symbol} afterClassName="max-w-[100px]" />
                </dd>
              </dl>
              <div className="flex items-center">
              <DivideVertical className="h-15" />
                <dl className="px-4">
                  <dt className="text-content-1">Market Cap</dt>
                  <dd className="text-8 font-medium">
                    <NumberView before="$" number={584512} />
                  </dd>
                </dl>
              </div>
              <div className="flex items-center">
              <DivideVertical className="h-15" />
                <dl className="px-4">
                  <dt className="text-content-1">Launch Price</dt>
                  <dd className="text-8 font-medium">
                    <NumberView number={token.price} after={token.anchor.symbol} afterClassName="max-w-[100px]" />
                  </dd>
                </dl>
              </div>
              <div className="flex items-center">
              <DivideVertical className="h-15" />
                <dl className="px-4">
                  <dt className="text-content-1">Holding Addresses</dt>
                  <dd className="text-8 font-medium">
                    <NumberView number={1000} />
                  </dd>
                </dl>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

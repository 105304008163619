import '@burve-front/react-ui/src/styles/index.css';
import './styles/index.css';
import 'virtual:uno.css';

import '@/utils/i18n';
import App from '@/app';
import { APP_NAME, TEST } from '@/confs/app';
import { consoleVersion, erudaConsole, fps, reportWebVitals } from '@burve-front/core';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
(() => {
  if (TEST) {
    erudaConsole();
    reportWebVitals((result) => console.log(result.name, ':', result.value));
    fps();
  }
  consoleVersion('0.0.1', APP_NAME); // TODO version
})();

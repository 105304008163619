import { ProjectConfirmedIcon, ProjectFailedIcon, ProjectPendingIcon, ProjectStarIcon } from '@/assets';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/ui';
import { DialogDescription } from '@radix-ui/react-dialog';
import { type PropsWithChildren, memo } from 'react';

const AnimateComponents = memo(() => {
  return (
    <>
      <div className="absolute blur size-full rounded-full bg-[#A866FF]/30 shadow-[0px_0px_34px_0px_rgba(168,102,255,0.62)]" />
      <ProjectStarIcon className="w-[3.81px] h-[6.28px] absolute left-[1.27px] top-[16.32px] fill-content animate-pulse" />
      <ProjectStarIcon className="w-[1.91px] h-[3.77px] absolute left-0 top-[25.11px]  fill-content animate-pulse delay-75" />
      <ProjectStarIcon className="w-[1.27px] h-[1.26px] absolute left-[7.63px] top-[40.17px] fill-content animate-pulse delay-50" />
      <ProjectStarIcon className="w-[1.91px] h-[3.77px] absolute left-[15.25px] top-[51.47px] fill-content animate-pulse delay-75" />
      <ProjectStarIcon className="w-[1.91px] h-[3.77px] absolute left-[36.86px] top-0 fill-content animate-pulse" />
      <ProjectStarIcon className="w-[3.81px] h-[6.28px] absolute left-[52.38px] top-[6.28px] fill-content animate-pulse delay-50" />
      <ProjectStarIcon className="w-[1.91px] h-[3.77px] absolute left-[57.2px] top-[11.3px] fill-content animate-pulse delay-100" />
      <ProjectStarIcon className="w-[1.91px] h-[3.77px] absolute left-[66.09px] top-[32.64px] fill-content animate-pulse delay-50" />
      <ProjectStarIcon className="w-[1.91px] h-[3.77px] absolute left-[47.03px] top-[53.98px] fill-content animate-pulse delay-75" />
      <ProjectStarIcon className="w-[2.54px] h-[5.02px] absolute left-[50.84px] top-[53.98px] fill-content animate-pulse delay-100" />
    </>
  );
});

type StatusDialogProps = PropsWithChildren & {
  status: 'pending' | 'failed' | 'confirmed';
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  title?: string;
};
export const StatusDialog = ({ isOpen, onOpenChange, status, title, children }: StatusDialogProps) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="px-14.5 pt-7.5 pb-6">
        <DialogHeader className="text-center relative items-center space-y-7.5">
          <div className="w-17 h-15 relative flex items-center justify-center">
            <AnimateComponents />
            {status === 'pending' && <ProjectPendingIcon className="size-10.5 animate-spin" />}
            {status === 'failed' && <ProjectFailedIcon className="size-10.5" />}
            {status === 'confirmed' && <ProjectConfirmedIcon className="size-10.5" />}
          </div>
          <DialogTitle className="font-medium text-6">{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription />
        {children}
      </DialogContent>
    </Dialog>
  );
};

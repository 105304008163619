import { useSwapStore } from '@/store/swap-store';
import { Badge, ProjectSettingIcon } from '@burve-front/react-ui';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  const { t } = useTranslation();
  const setViewMode = useSwapStore((state) => state.setViewMode);
  const slippage = useSwapStore((state) => state.slippage);
  return (
    <div className="flex items-center space-x-1">
      <h2 className="text-6 font-500">{t('swap')}</h2>
      <ProjectSettingIcon
        onClick={() => setViewMode('setting')}
        className="size-6 cursor-pointer transition-transform duration-200 hover:rotate-180"
      />
      <Badge variant="primary" className="h-5">
        {slippage}%
      </Badge>
    </div>
  );
};

import {
  type CandlestickData,
  type ChartOptions,
  ColorType,
  CrosshairMode,
  type DeepPartial,
  type HistogramData,
  type HistogramSeriesPartialOptions,
  type IChartApi,
  type ISeriesApi,
  LineStyle,
  type MouseEventParams,
  type SeriesDataItemTypeMap,
  type Time,
  createChart,
} from 'lightweight-charts';
import { useEffect, useRef } from 'react';

type KLineChartProps = {
  candlestickSeries: {
    data: SeriesDataItemTypeMap['Candlestick'][];
    options?: HistogramSeriesPartialOptions;
  };
  histogramSeries: {
    data: SeriesDataItemTypeMap['Histogram'][];
    options?: HistogramSeriesPartialOptions;
  };
  className?: string;
  options?: Omit<DeepPartial<ChartOptions>, 'width' | 'height'>;
};
const borderColor = '#49417c';
const textColor = '#9ca1b6';
const backgroundColor = 'transparent';

const getCrosshairDataPoint = (series: ISeriesApi<'Candlestick' | 'Histogram'>, param: MouseEventParams<Time>) => {
  if (!param.time) return null;
  const dataPoint = param.seriesData.get(series) as HistogramData<Time> | CandlestickData<Time> | null;
  return dataPoint || null;
};

const syncCrosshair = (
  chart: IChartApi,
  series: ISeriesApi<'Candlestick' | 'Histogram'>,
  dataPoint: HistogramData<Time> | CandlestickData<Time> | null,
) => {
  if (dataPoint) {
    const value = (dataPoint as { open?: number })?.open
      ? ((dataPoint as CandlestickData<Time>).close + (dataPoint as CandlestickData<Time>).open) / 2
      : (dataPoint as HistogramData<Time>).value;
    chart.setCrosshairPosition(value, dataPoint.time, series);
    return;
  }
  chart.clearCrosshairPosition();
};

export const KLineChart = ({ histogramSeries, candlestickSeries, className, options }: KLineChartProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!chartContainerRef.current) return;
    const partHeight = chartContainerRef.current.clientHeight / 10;
    const cHeight = partHeight * 7;
    const hHeight = partHeight * 3;
    const width = chartContainerRef.current.clientWidth;
    const baseOptions: DeepPartial<ChartOptions> = {
      ...options,
      layout: {
        textColor,
        attributionLogo: false,
        ...options?.layout,
        background: { type: ColorType.Solid, color: backgroundColor, ...options?.layout?.background },
      },
      crosshair: {
        ...options?.crosshair,
        vertLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.vertLine },
        horzLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.horzLine },
      },
      grid: {
        vertLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.vertLines },
        horzLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.horzLines },
      },
      leftPriceScale: { borderColor, ...options?.leftPriceScale, minimumWidth: 52 },
      rightPriceScale: { borderColor, ...options?.rightPriceScale, minimumWidth: 52 },
      timeScale: { borderColor, ...options?.timeScale },
      width,
    };
    const cChart = createChart(chartContainerRef.current, {
      ...baseOptions,
      crosshair: { ...baseOptions.crosshair, mode: CrosshairMode.Normal },
      timeScale: { visible: false },
      height: cHeight,
    });
    const hChart = createChart(chartContainerRef.current, {
      ...baseOptions,
      height: hHeight,
    });
    const handleResize = () => {
      if (!chartContainerRef.current) return;
      const options = { width: chartContainerRef.current.clientWidth };
      cChart.applyOptions(options);
      hChart.applyOptions(options);
    };
    const cSeries = cChart.addCandlestickSeries({
      upColor: '#5CE5AC',
      downColor: '#F5537A',
      borderVisible: false,
      wickUpColor: '#5CE5AC',
      wickDownColor: '#F5537A',
      ...candlestickSeries.options,
    });
    const hSeries = hChart.addHistogramSeries({
      priceFormat: { type: 'volume' },
      lastValueVisible: false,
      priceLineVisible: false,
      ...histogramSeries.options,
    });
    cSeries.setData(candlestickSeries.data);
    hSeries.setData(histogramSeries.data);
    cChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
      if (timeRange) hChart.timeScale().setVisibleLogicalRange(timeRange);
    });
    hChart.timeScale().subscribeVisibleLogicalRangeChange((timeRange) => {
      if (timeRange) cChart.timeScale().setVisibleLogicalRange(timeRange);
    });
    cChart.timeScale().fitContent();
    hChart.timeScale().fitContent();
    cChart.subscribeCrosshairMove((param) => syncCrosshair(hChart, hSeries, getCrosshairDataPoint(cSeries, param)));
    hChart.subscribeCrosshairMove((param) => syncCrosshair(cChart, cSeries, getCrosshairDataPoint(hSeries, param)));
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      cChart.remove();
      hChart.remove();
    };
  }, [histogramSeries, candlestickSeries, options]);

  return <div ref={chartContainerRef} className={className} />;
};

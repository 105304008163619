import type { NavItem } from '@/components/nav/nav-item';
import { TheErrorPage } from '@/pages/error';
import ThePortoflioPage from '@/pages/explore';
import { ThePoolDetailPage } from '@/pages/pool-detail';
import ThePoolsPage from '@/pages/pools';
import TheSwapPage from '@/pages/swap';
import { TheTokenDetailPage } from '@/pages/token-detail';

import type { FC, ReactNode } from 'react';

export type RouteItem = {
  name: string;
  component: FC | JSX.Element;
  fallback?: ReactNode;
} & ({ path: string; children?: never } | { path?: never; children: RouteItem[] });

export const routes: RouteItem[] = [
  {
    path: '/',
    name: 'explore',
    component: ThePortoflioPage,
  },
  {
    path: '/pools',
    name: 'pools',
    component: ThePoolsPage,
  },
  {
    path: '/swap',
    name: 'swap',
    component: TheSwapPage,
  },
  {
    path: '/pool/:address',
    name: 'pool',
    component: ThePoolDetailPage,
  },
  {
    path: '/token/:address',
    name: 'token',
    component: TheTokenDetailPage,
  },
  {
    path: '*',
    name: 'error',
    component: TheErrorPage,
  },
];

export const links: NavItem[] = [
  {
    label: 'Explore',
    href: '/',
  },
  {
    label: 'Pools',
    href: '/pools',
  },
  {
    label: 'Swap',
    href: '/swap',
  },
  {
    label: 'Terminal',
    href: 'https://terminal.burve.io',
  },
];

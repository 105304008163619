import { EVM_DEFAULT_CHAIN } from '@/confs/evm';
import { wagmiConf } from '@/utils/wagmi-conf';
import { useCallback, useMemo, useState } from 'react';
import type { Chain } from 'viem';
import { useAccount, useChainId, useChains, useSwitchChain } from 'wagmi';

// chain
const useEvmChain = () => {
  const chianId = useChainId();
  const chains = useChains();
  const { chain: walletChain } = useAccount();
  const { switchChainAsync, isPending, error, reset } = useSwitchChain();
  const [pendingChain, setPendingChain] = useState<Chain | undefined>();
  const chain = useMemo(() => chains?.find((item) => item.id === chianId) || EVM_DEFAULT_CHAIN, [chains, chianId]);
  const switchChain = useCallback(
    async (chainId: number) => {
      const find = chains?.find((item) => item.id === chainId);
      if (pendingChain !== undefined || !find) return;
      try {
        setPendingChain(find);
        if (chainId !== walletChain?.id) {
          await switchChainAsync?.({ chainId });
        }
        wagmiConf.setState((s) => ({ ...s, chainId }));
      } catch (error) {
        reset();
        return Promise.reject(error);
      } finally {
        setPendingChain(undefined);
      }
    },
    [chains, pendingChain, reset, switchChainAsync, walletChain?.id],
  );

  return {
    chianId,
    chain,
    walletChain,
    pendingChain,
    chains,
    isPending,
    switchChain,
    error,
  };
};

export default useEvmChain;

import { cn } from '@/utils';
import { memo } from 'react';
import { SpanLoading } from './span-loading';

export const BlockLoading = memo(({ className }: { className?: string }) => {
  return (
    <div className={cn('mx-auto text-center flex items-center justify-center pt-5 pb-20', className)}>
      <SpanLoading />
    </div>
  );
});

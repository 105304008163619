import { DEFAULT_CHAIN, DEFAULT_CHAINS } from '@/confs/chain';
import { SOLANA_CHAINS } from '@/confs/solana';
import { EChainType } from '@burve-front/core';
import { getSearchParam, setSearchParam } from '@burve-front/core';
import type { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { useEffect, useMemo } from 'react';
import { useSearch } from 'wouter';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import useEvmChain from './use-evm-chain';
import useSolanaNetwork from './use-solana-network';

// save key
export const serchKey = 'chain';
export const ChainSaveKey = 'chain';
export interface IChainState {
  chainName: string;
  setChainName: (chainName: string) => void;
}
// store
const useChainStore = createWithEqualityFn<IChainState>()(
  persist(
    (set) => ({
      chainName: DEFAULT_CHAIN.name,
      setChainName(chainName: string) {
        set({ chainName });
      },
    }),
    { name: ChainSaveKey },
  ),
  shallow,
);

const useChain = () => {
  const searchParams = useSearch();
  const searchChainName = useMemo(() => new URLSearchParams(searchParams).get(serchKey)?.toLowerCase(), [searchParams]);
  const { chainName, setChainName } = useChainStore();
  const { chains: evmChains, switchChain, isPending: isEvmPending, pendingChain: evmPendingChain } = useEvmChain();
  const { switchNetwork, isPending: isSolanaPending, pendingNetwork } = useSolanaNetwork();
  const searchChain = useMemo(
    () =>
      searchChainName ? DEFAULT_CHAINS.find((item) => item.name.toLowerCase() === searchChainName.toLowerCase()) : undefined,
    [searchChainName],
  );
  const chain = useMemo(
    () => DEFAULT_CHAINS.find((item) => item.name.toLowerCase() === chainName.toLowerCase()) || DEFAULT_CHAIN,
    [chainName],
  );
  const chainType = useMemo(() => (chain?.custom?.isSolana ? EChainType.Solana : EChainType.Evm), [chain?.custom?.isSolana]);
  const supplyChains = useMemo(() => (chainType === EChainType.Evm ? evmChains : SOLANA_CHAINS), [chainType, evmChains]);
  const solanaPendingChain = useMemo(
    () => SOLANA_CHAINS.find((item) => item.custom.network === pendingNetwork),
    [pendingNetwork],
  );
  const isPending = useMemo(() => isEvmPending || isSolanaPending, [isEvmPending, isSolanaPending]);
  const pendingChain = useMemo(
    () => (chainType === EChainType.Evm ? evmPendingChain : chainType === EChainType.Solana ? solanaPendingChain : undefined),
    [chainType, evmPendingChain, solanaPendingChain],
  );
  const handledSwitchChain = async (chainName: string) => {
    const chain = DEFAULT_CHAINS.find((item) => item.name.toLowerCase() === chainName.toLowerCase());
    if (!chain) return;
    if (chain?.custom?.isSolana) {
      switchNetwork(chain.custom.network as WalletAdapterNetwork);
    } else {
      await switchChain(DEFAULT_CHAIN.id);
    }
    setChainName(chain?.name);
  };

  useEffect(() => {
    const searchName = getSearchParam(serchKey);
    if (searchName?.toLowerCase() !== chain.name.toLowerCase()) setSearchParam(serchKey, chain.name.toLowerCase());
  }, [chain.name]);

  return {
    searchChainName,
    chainType,
    chain,
    searchChain,
    supplyChains,
    chains: DEFAULT_CHAINS,
    pendingChain,
    switchChain: handledSwitchChain,
    isPending,
  };
};

export default useChain;

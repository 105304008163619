import { Pool } from '@/types/pool';
import { AddressView, NumberView, PairAvatar } from '@burve-front/react-ui';

type PoolItemProps = { pool: Pool };
export const PoolItem = ({ pool }: PoolItemProps) => {
  return (
    <a
      href={`/pool/${pool.address}`}
      target="_blank"
      className="h-12 flex cursor-pointer items-center justify-between whitespace-nowrap rounded-1 p-2 text-sm text-white hover:bg-[#9756AD]/20"
      rel="noreferrer">
      <div className="flex flex-1 items-center space-x-1">
        <PairAvatar one={pool.oneLogo} two={pool.twoLogo} />
        <div className="max-w-[270px] truncate whitespace-nowrap">{pool.name}</div>
        <span>-</span>
        <span>{pool.symbol}</span>
      </div>
      <AddressView address={pool.address} showCopy={false} className="w-30" />
      <div className="w-22 text-right">
        <NumberView before="$" number={pool.tvl} />
      </div>
    </a>
  );
};

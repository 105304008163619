import { combine } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

// store
export const useModalStore = createWithEqualityFn(
  combine(
    {
      isConnectModalOpen: false,
      isChainModalOpen: true,
      isRecentModalOpen: false,
    },
    (set) => {
      return {
        setIsConnectModalOpen(isConnectModalOpen: boolean) {
          set({ isConnectModalOpen });
        },
        setIsChainModalOpen(isChainModalOpen: boolean) {
          set({ isChainModalOpen });
        },
        setIsRecentModalOpen(isRecentModalOpen: boolean) {
          set({ isRecentModalOpen });
        },
      };
    },
  ),
  shallow,
);
export default useModalStore;

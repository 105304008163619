import useChain from '@/hooks/use-chain';
import { useModalStore } from '@/store/modal-store';
import { EChainType } from '@burve-front/core';
import { Button, CommonWalletIcon, SpanLoading } from '@burve-front/react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';

import { useMedia } from 'react-use';
import { EvmUserMenu } from './evm-user-menu';
import { SolansUserMenu } from './solana-use-menu';

export type ConnectWalletProps = {
  label?: string;
};

export const ConnectWallet = ({ label }: ConnectWalletProps) => {
  const isMobile = useMedia('(max-width: 767px)');
  const { t } = useTranslation();
  const viewLabel = useMemo(() => label ?? t('connect-wallet'), [label, t]);
  const { isConnected, isConnecting } = useAccount();
  const { connected, connect, wallet } = useWallet();
  const setIsConnectModalOpen = useModalStore((state) => state.setIsConnectModalOpen);
  const { chainType } = useChain();

  // auto connect to solana
  useEffect(() => {
    if (!wallet || connected || wallet.readyState !== 'Installed') return;
    connect();
  }, [connect, connected, wallet]);

  if (isConnected && chainType === EChainType.Evm) {
    return <EvmUserMenu />;
  }
  if (connected && chainType === EChainType.Solana) {
    return <SolansUserMenu />;
  }

  return (
    <Button
      variant={isMobile ? 'primary' : 'secondary'}
      size="sm"
      aria-label={viewLabel}
      onClick={() => setIsConnectModalOpen(true)}
      className="h-8 px-2 space-x-1">
      {isConnecting && <SpanLoading />}
      <CommonWalletIcon className="size-5 fill-white md:hidden" />
      <span className="hidden md:inline">{viewLabel}</span>
    </Button>
  );
};

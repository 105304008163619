import {
  Button,
  CommonSearchIcon,
  Dialog,
  DialogContent,
  DialogTrigger,
  Input,
  ProjectArrowLeftIcon,
  ProjectArrowRightIcon,
} from '@burve-front/react-ui';
import { useState } from 'react';
import { AllPool } from './all-pool';
import { TrendingPool } from './trending-pool';

const tabs = [
  {
    value: 'trending',
  },
  {
    value: 'all',
  },
];

export const SearchPool = () => {
  const [select, setSelect] = useState(tabs[0].value);
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="size-8 p-0">
          <CommonSearchIcon className="size-3.5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[794px] max-w-[640px] w-full p-0" closeClassName="top-3">
        <div className="h-8 w-full flex items-center border-b p-5">
          <CommonSearchIcon className="mr-2 size-3.5" />
          <Input
            variant="ghost"
            className="size-full h-8 w-full cursor-pointer border-none pr-6 text-xs"
            placeholder="Search pool name/token symbol/contract address"
          />
        </div>
        <div className="m-3 mt-5 text-sm text-content-1">
          <div className="mx-2 flex items-center justify-between">
            <span className="flex-1">Pool</span>
            <span className="w-30">Contract</span>
            <span className="w-22 text-right">TVL</span>
          </div>
          <div className="max-h-[584px] w-full overflow-auto">{select === 'trending' ? <TrendingPool /> : <AllPool />}</div>
        </div>
        <div className="w-full flex items-center justify-center border-t py-2">
          {select === 'trending' && (
            <Button
              variant="link"
              onClick={() => setSelect('all')}
              className="cursor-pointer p-0 text-sm text-primary underline-transparent hover:translate-x-1">
              See all results
              <ProjectArrowRightIcon className="ml-2 h-2 w-2.5 fill-current" />
            </Button>
          )}
          {select === 'all' && (
            <Button
              variant="link"
              onClick={() => setSelect('trending')}
              className="cursor-pointer p-0 text-sm text-primary underline-transparent hover:-translate-x-1">
              <ProjectArrowLeftIcon className="mr-1 h-2 w-2.5 fill-current" />
              Back
            </Button>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

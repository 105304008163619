import { Input } from '@burve-front/react-ui';
import { useState } from 'react';
import { Select } from '../select';

export const Pay = () => {
  const [pay, setPay] = useState('23.11');
  return (
    <div className="h-33.5 border-gradient-primary rounded-lg p-4 text-content-1 text-sm space-y-2">
      <div>
        <h3 className="">Pay</h3>
      </div>
      <div className="flex items-center justify-between">
        <Input
          variant="ghost"
          className="text-[32px] text-content"
          placeholder="0.00"
          value={pay}
          onChange={({ currentTarget }) => setPay(currentTarget.value)}
        />
        <Select />
      </div>
      <div className="flex items-center justify-between">
        <div>≈ $35.12</div>
        <div>Available: 100 USDT</div>
      </div>
    </div>
  );
};

import { Confirm } from '../confirm';
import { Pay } from './pay';
import { Receive } from './receive';
import { Switch } from './switch';

export const InputView = () => {
  return (
    <div>
      <div className="relative">
        <Pay />
        <Receive />
        <Switch />
      </div>
      <Confirm />
    </div>
  );
};

import { APP_I18N, TEST } from '@/confs/app';
import i18next from 'i18next';
import backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

i18next
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: APP_I18N.defaultLocale,
    fallbackLng: APP_I18N.defaultLocale,
    supportedLngs: APP_I18N.locales.map((item) => item.lang),
    debug: TEST,
    backend: {
      loadPath: '/lang/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;

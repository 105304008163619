import { SplideSlide } from 'react-splide-ts';

export type BannerItem = {
  id: number;
  img: string;
  href?: string;
};

type BannerItemProps = {
  item: BannerItem;
};

export const BannerItem = ({ item }: BannerItemProps) => {
  return (
    <SplideSlide className="relative h-30 w-full lg:h-[270px]">
      <img
        className="h-30 w-full object-cover lg:h-[270px] lg:object-contain"
        src={item.img}
        aria-label={item.href}
        height="405"
        width="2160"
      />
      {item.href && (
        <div className="absolute left-0 top-0 size-full">
          <a
            href={item.href}
            aria-label={item.href}
            target="_blank"
            rel="noreferrer"
            className="mx-auto block size-full max-w-[1000px] cursor-pointer"
          />
        </div>
      )}
    </SplideSlide>
  );
};

export const DEV = import.meta.env.DEV;
export const PROD = import.meta.env.PROD;
export const PREVIEW = import.meta.env.MODE === 'preview';
export const TEST = DEV || PREVIEW;

export const APP_I18N = {
  defaultLocale: 'en',
  locales: [
    { name: 'English', lang: 'en' },
    { name: '简体中文', lang: 'zh' },
  ],
};

import { SOLANA_CHAINS, SOLANA_DEFAULT_CHAIN } from '@/confs/solana';
import type { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import { useMemo, useState } from 'react';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

// save key
export const NetworkSaveKey = 'network';
export interface INetworkState {
  network: WalletAdapterNetwork;
  setNetwork: (network: WalletAdapterNetwork) => void;
}

// store
const useNetworkStore = createWithEqualityFn<INetworkState>()(
  persist(
    (set) => ({
      network: SOLANA_DEFAULT_CHAIN.custom.network as WalletAdapterNetwork,
      setNetwork(network: WalletAdapterNetwork) {
        set({ network });
      },
    }),
    { name: NetworkSaveKey },
  ),
  shallow,
);

const useSolanaNetwork = () => {
  const { network, setNetwork } = useNetworkStore();
  const networks = useMemo(() => SOLANA_CHAINS.map((item) => item.custom.network), []);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [pendingNetwork, setPendingNetwork] = useState<WalletAdapterNetwork>();
  const endpoint = useMemo(() => clusterApiUrl(network), [network]);
  const switchNetwork = async (network: WalletAdapterNetwork) => {
    const unsupported = networks.some((item) => item.toLowerCase() === network.toLowerCase());
    if (!unsupported) return;
    setIsPending(true);
    setPendingNetwork(pendingNetwork);
    setNetwork(network);
    setIsPending(false);
    setPendingNetwork(undefined);
  };
  return {
    network,
    networks,
    endpoint,
    isPending,
    pendingNetwork,
    switchNetwork,
  };
};

export default useSolanaNetwork;

import { transactions } from '@/apis/mocks/transaction';
import { Pool } from '@/types/pool';
import { Translation } from '@/types/translation';
import { AddressView, NumberView, VirtualTable } from '@burve-front/react-ui';
import { ColumnDef, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { format } from 'date-fns/format';
import { Address } from 'viem';
type TransactionProps = {
  pool: Pool;
};

const columns: ColumnDef<Translation, any>[] = [
  {
    id: 'name',
    enableSorting: false,
    header: () => <span>Tokens</span>,
    accessorKey: 'hash',
    cell: () => {
      return (
        <a href="#" className="whitespace-nowrap text-primary">
          Swap BNB to DAISY
        </a>
      );
    },
  },
  {
    id: 'type',
    header: () => <span>Type</span>,
    accessorKey: 'type',
    cell: (info) => <span className="text-success">{info.getValue()}</span>,
  },
  {
    id: 'price',
    header: () => <span>Price USD</span>,
    accessorKey: 'price',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'totalValue',
    header: () => <span>Total Value</span>,
    accessorKey: 'totalValue',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'tokenAmount',
    header: () => <span>DAISY</span>,
    accessorKey: 'tokenAmount',
    cell: (info) => <NumberView number={info.getValue()} />,
  },
  {
    id: 'anchorAmount',
    header: () => <span>BNB</span>,
    accessorKey: 'anchorAmount',
    cell: (info) => <NumberView number={info.getValue()} />,
  },
  {
    id: 'account',
    header: () => <span>Account</span>,
    accessorKey: 'account',
    cell: (info) => <AddressView address={info.getValue() as Address} showCopy={false} />,
  },
  {
    id: 'timestamp',
    header: () => <span>Time</span>,
    accessorKey: 'timestamp',
    cell: (info) => <span>{format(info.getValue() * 1e3, 'MMM dd, HH:mm')}</span>,
  },
];
export const Transaction = ({ pool }: TransactionProps) => {
  const table = useReactTable({
    data: transactions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !import.meta.env.PROD,
  });
  console.log(pool);
  return <VirtualTable table={table} className="h-[343px]" firstClassName="min-w-[120px]" trClassName="pr-5" />;
};

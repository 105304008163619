import { cn } from '@/utils';
import { type Toast, ToastBar, Toaster, type ToasterProps, toast } from 'react-hot-toast';
import {
  CommonErrorCircleIcon,
  CommonInfoCircleIcon,
  CommonLoadingCircleIcon,
  CommonSuccessCircleIcon,
  CommonWarningCircleIcon,
} from '../../assets';

export type Options =
  | Partial<Pick<Toast, 'icon' | 'duration' | 'ariaProps' | 'className' | 'style' | 'position' | 'iconTheme'>>
  | undefined;

export const info: Options = {
  icon: <CommonInfoCircleIcon className="size-4" />,
  // iconTheme: {
  //   primary: 'hsl(var(--inu))',
  //   secondary: 'hsl(var(--inc))',
  // },
};

export const success: Options = {
  icon: <CommonSuccessCircleIcon className="size-4" />,
};

export const warning: Options = {
  icon: <CommonWarningCircleIcon className="size-4" />,
};

export const error: Options = {
  icon: <CommonErrorCircleIcon className="size-4" />,
};

const loading: Options = {
  icon: <CommonLoadingCircleIcon className="size-4 animate-spin fill-current" />,
};

export const toastOptions: ToasterProps['toastOptions'] = {
  success,
  error,
  loading,
};

export const CustomToaster = ({ ...attrs }: ToasterProps) => (
  <Toaster {...attrs} toastOptions={toastOptions}>
    {(t) => (
      <ToastBar toast={t} style={t.style}>
        {({ icon, message }) => {
          return (
            <div className={cn('flex', t.className)}>
              <div className="mt-[6px]">{icon}</div>
              <div className="max-w-[308px] flex-1 break-words text-sm">{message}</div>
              <div className="flex items-center">
                {t.type !== 'loading' && (
                  <button
                    type="button"
                    title="close dismiss"
                    className="duration-200 hover:-rotate-90"
                    onClick={() => t && toast.dismiss(t.id)}>
                    ✕
                  </button>
                )}
              </div>
            </div>
          );
        }}
      </ToastBar>
    )}
  </Toaster>
);

import { Token } from '@/types/token';

export const tokens: Token[] = [
  {
    type: 'hodl',
    address: '0x1',
    name: 'DOG•GO•TO•THE•MOONWBTC',
    symbol: 'CIRUS',
    logo: '/imgs/tokens/cirus.png',
    price: 100,
    priceChange: -10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'CIRUS',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdc.png',
      address: '0x2',
      name: 'USDC',
      symbol: 'USDC',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'hodl',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'lol',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    cMarketCap: 0,
    volume: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'upcoming',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    volume: 0,
    cMarketCap: 0,
    openTime: Math.ceil(new Date().getTime() / 1e3) + 60 * 60,
    openPrice: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'upcoming',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    volume: 0,
    cMarketCap: 0,
    openTime: Math.ceil(new Date().getTime() / 1e3) + 60 * 60 * 24,
    openPrice: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
  {
    type: 'upcoming',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    logo: '/imgs/tokens/pol.png',
    price: 100,
    priceChange: 10,
    volume: 0,
    cMarketCap: 0,
    openTime: Math.ceil(new Date().getTime() / 1e3) + 2 * 60 * 60 * 24,
    openPrice: 0,
    anchor: {
      type: 'erc20',
      logo: '/imgs/tokens/usdt.png',
      address: '0x2',
      name: 'USDT',
      symbol: 'USDT',
    },
  },
];

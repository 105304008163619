import { cn } from '@/utils';
import { ProjectAscIcon, ProjectDescIcon } from '../../assets';

type ColumnSortProps = {
  type: 'asc' | 'desc' | false;
};
export const ColumnSort = ({ type }: ColumnSortProps) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-1">
      <ProjectAscIcon className={cn('w-2 h-1', type === 'asc' ? 'fill-[#acb0c8]' : 'fill-[#797D95]/40')} />
      <ProjectDescIcon className={cn('w-2 h-1', type === 'desc' ? 'fill-[#acb0c8]' : 'fill-[#797D95]/40')} />
    </div>
  );
};

import { Translation } from '@/types/translation';

export const transactions: Translation[] = [
  {
    type: 'Mint',
    hash: '0x22255848848',
    timestamp: Math.ceil(new Date().getTime() / 1e3),

    price: 1.2,
    totalvalue: 20,
    tokenAmount: 20,
    anchorAmount: 1000,
    account: '0x2688484848',
    token: '0x1',
  },
];

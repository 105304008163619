import { Input, ProjectRefetchIcon } from '@burve-front/react-ui';
import { useState } from 'react';
import { Select } from '../select';

export const Receive = () => {
  const [receive, setReceive] = useState('12,457.11');
  return (
    <div className="h-33.5 border-gradient-primary rounded-lg p-4 text-content-1 text-sm space-y-2 mt-2">
      <div className="flex items-center space-x-1">
        <h3 className="">Receive</h3>
        <ProjectRefetchIcon className="size-3 cursor-pointer transition-transform duration-200 hover:rotate-180" />
      </div>
      <div className="flex items-center justify-between">
        <Input
          variant="ghost"
          className="text-[32px] text-content"
          placeholder="0.00"
          value={receive}
          onChange={({ currentTarget }) => setReceive(currentTarget.value)}
        />
        <Select />
      </div>
      <div className="flex items-center justify-between">
        <div>≈ $35.12</div>
        <div>Available: 100 USDT</div>
      </div>
    </div>
  );
};

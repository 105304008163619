import { APP_THEME, APP_THEMES } from '@/confs/app';
import { getTheme, setTheme } from '@burve-front/core';
import { useCallback } from 'react';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

// save key
export const ThemeSaveKey = 'theme';
export interface IThemeState {
  theme: string;
  themes: string[];
  setTheme: (theme: string) => void;
  setThemes: (themes: string[]) => void;
}

// store
export const useThemeStore = createWithEqualityFn<IThemeState>()(
  persist(
    (set, get) => ({
      theme: APP_THEME,
      themes: APP_THEMES,
      setTheme(theme: string) {
        if (theme !== get().theme) set({ theme });
      },
      setThemes(themes: string[]) {
        set({ themes });
      },
    }),
    { name: ThemeSaveKey },
  ),
  shallow,
);

export type useThemeProps = Record<string, never> | undefined;

const useTheme = () => {
  const { theme, themes, setTheme: storeSetTheme, setThemes } = useThemeStore((state) => ({ ...state }));
  const switchTheme = useCallback(
    (newTheme: string) => {
      const tmpTheme = ['auto', 'system'].some((item) => item === newTheme) ? getTheme() || theme : newTheme;
      setTheme(tmpTheme);
      storeSetTheme(newTheme);
    },
    [storeSetTheme, theme],
  );

  return {
    theme,
    themes,
    setThemes,
    switchTheme,
  };
};

export default useTheme;

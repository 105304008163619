import { cn } from '@/utils';
import { getGradientSVG } from '@burve-front/core';
import { useEffect, useRef } from 'react';
import type { Address } from 'viem';

type UserAvatarProps = {
  className?: string;
  address?: Address | string;
  size?: number;
};

export const UserAvatar = ({ address, size = 26, ...attrs }: UserAvatarProps) => {
  if (!address) return null;
  const svg = useRef(getGradientSVG(address, size, size, true));
  const ref = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (ref.current) ref.current.innerHTML = svg.current;
  }, []);
  return (
    <svg
      {...attrs}
      ref={ref}
      className={cn('rounded-full size-[26px]', attrs.className)}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    />
  );
};

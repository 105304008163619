import { tokens } from '@/apis/mocks';
import { useAdStore } from '@/components/ad-banner/ad-store';
import { Banner } from '@/components/banner';
import { SearchToken } from '@/components/search-token';
import { SortDay } from '@/components/sort/sort-day';
import { Token, UpcomingToken } from '@/types/token';
import {
  Countdown,
  LargeSmallAvatar,
  NumberView,
  ProjectFairIcon,
  ProjectMemeIcon,
  ProjectTrendingIcon,
  ProjectUpcomingIcon,
  RiseView,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  VirtualTable,
  cn,
} from '@burve-front/react-ui';
import { type ColumnDef, Row, Table, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useLocation } from 'wouter';

const columns: ColumnDef<Token>[] = [
  {
    id: 'token',
    enableSorting: false,
    header: () => <span>Tokens</span>,
    accessorKey: 'address',
    cell: ({ row }) => {
      return (
        <div className="flex items-center space-x-1">
          <LargeSmallAvatar large={row.original.logo} small={row.original.anchor.logo} />
          <span>{row.original.name}</span>
          <span>/</span>
          <span>{row.original.symbol}</span>
        </div>
      );
    },
  },
  {
    id: 'price',
    header: () => <span>Price</span>,
    accessorKey: 'price',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'priceChange',
    header: () => <span>Change</span>,
    accessorKey: 'priceChange',
    cell: (info) => <RiseView number={info.getValue()} />,
  },
  {
    id: 'cMarketCap',
    header: () => <span>C.MarketCap</span>,
    accessorKey: 'cMarketCap',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'volume',
    header: () => <span>Volume</span>,
    accessorKey: 'volume',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
];

const upcomingColumns: ColumnDef<UpcomingToken>[] = [
  {
    id: 'token',
    enableSorting: false,
    header: () => <span>Tokens</span>,
    accessorKey: 'address',
    cell: ({ row }) => {
      return (
        <div className="flex items-center space-x-1">
          <LargeSmallAvatar large={row.original.logo} small={row.original.anchor.logo} />
          <span>{row.original.name}</span>
          <span>/</span>
          <span>{row.original.symbol}</span>
        </div>
      );
    },
  },
  {
    id: 'openPrice',
    header: () => <span>Launch Price</span>,
    accessorKey: 'openPrice',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'cMarketCap',
    header: () => <span>C.MarketCap</span>,
    accessorKey: 'cMarketCap',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'openTime',
    header: () => <span>ETA</span>,
    accessorKey: 'openTime',
    cell: (info) => <Countdown className="text-warn" endDate={new Date((info.getValue() as number) * 1e3)} />,
  },
];

const TabsHeader = () => {
  const isCloseAd = useAdStore((state) => state.isCloseAd);
  return (
    <div
      className={cn(
        'sticky z-10 flex items-center justify-between py-2 backdrop-blur',
        isCloseAd ? 'top-15.5 md:top-17.5' : 'top-24.5 md:top-26.5',
      )}>
      <TabsList variant="primary" className="space-x-3">
        <TabsTrigger variant="primary" value="trending">
          <ProjectTrendingIcon className="mr-1 size-4 fill-current" />
          Trending
        </TabsTrigger>
        <TabsTrigger variant="primary" value="hodl">
          <ProjectFairIcon className="mr-1 size-4 fill-current" />
          Fair Launch (HODL)
        </TabsTrigger>
        <TabsTrigger variant="primary" value="lol">
          <ProjectMemeIcon className="mr-1 size-4 fill-current" />
          Meme (LOL)
        </TabsTrigger>
        <TabsTrigger variant="primary" value="upcoming">
          <ProjectUpcomingIcon className="mr-1 size-4 fill-current" />
          Upcoming Listing
        </TabsTrigger>
      </TabsList>
      <div className="space-x-3">
        <SearchToken />
        <SortDay />
      </div>
    </div>
  );
};

type TrendingProps = {
  table: Table<Token>;
  onRowClick: (row: Row<Token>) => void;
};
const Trending = ({ table, onRowClick }: TrendingProps) => {
  const isCloseAd = useAdStore((state) => state.isCloseAd);
  return (
    <VirtualTable
      table={table}
      onRowClick={onRowClick}
      headClassName={cn(isCloseAd ? 'top-29 md:top-31' : 'top-38 md:top-40')}
    />
  );
};

type HodlProps = {
  table: Table<Token>;
  onRowClick: (row: Row<Token>) => void;
};
const Hodl = ({ table, onRowClick }: HodlProps) => {
  const isCloseAd = useAdStore((state) => state.isCloseAd);
  return (
    <VirtualTable
      table={table}
      onRowClick={onRowClick}
      headClassName={cn(isCloseAd ? 'top-29 md:top-31' : 'top-38 md:top-40')}
    />
  );
};

type LOLProps = {
  table: Table<Token>;
  onRowClick: (row: Row<Token>) => void;
};
const LOL = ({ table, onRowClick }: LOLProps) => {
  const isCloseAd = useAdStore((state) => state.isCloseAd);
  return (
    <VirtualTable
      table={table}
      onRowClick={onRowClick}
      headClassName={cn(isCloseAd ? 'top-29 md:top-31' : 'top-38 md:top-40')}
    />
  );
};

type UpcomingProps = {
  table: Table<UpcomingToken>;
  onRowClick: (row: Row<UpcomingToken>) => void;
};
const Upcoming = ({ table, onRowClick }: UpcomingProps) => {
  const isCloseAd = useAdStore((state) => state.isCloseAd);
  return (
    <VirtualTable
      table={table}
      onRowClick={onRowClick}
      headClassName={cn(isCloseAd ? 'top-29 md:top-31' : 'top-38 md:top-40')}
    />
  );
};

const ThePortoflioPage = () => {
  const table = useReactTable({
    data: tokens.filter((item) => item.type !== 'upcoming'),
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !import.meta.env.PROD,
  });
  const upcomingTable = useReactTable({
    data: tokens.filter((item) => item.type === 'upcoming'),
    columns: upcomingColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !import.meta.env.PROD,
  });
  const [, navigate] = useLocation();
  const handleRowClick = (row: Row<Token>) => {
    navigate(`/token/${row.original.address}`);
  };
  return (
    <>
      <Banner />
      <Tabs defaultValue="trending" className="mx-auto mb-8 max-w-[1100px] w-full lg:mb-21 lg:pt-8">
        <TabsHeader />
        <TabsContent value="trending">
          {/* TODO close AD bug */}
          <Trending table={table} onRowClick={handleRowClick} />
        </TabsContent>
        <TabsContent value="hodl">
          <Hodl table={table} onRowClick={handleRowClick} />
        </TabsContent>
        <TabsContent value="lol">
          <LOL table={table} onRowClick={handleRowClick} />
        </TabsContent>
        <TabsContent value="upcoming">
          <Upcoming table={upcomingTable} onRowClick={handleRowClick} />
        </TabsContent>
      </Tabs>
    </>
  );
};

export default ThePortoflioPage;

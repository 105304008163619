import { Avatar, type Icon } from '@burve-front/react-ui';
import { Handle, type NodeProps } from 'reactflow';

export type NodeData = {
  icon?: Icon;
  symbol?: string;
};
export type CustomNodeProps = NodeProps<NodeData>;
export const CustomNode = ({ data, ...props }: CustomNodeProps) => {
  return (
    <div className="shadow-md">
      <div className="flex h-[40px] w-[100px] items-center space-x-1 rounded-full border border-second backdrop-blur px-3 py-2 ">
        <Avatar avatar={data.icon} className="size-6 rounded-full" />
        <span className="inline-block truncate">{data.symbol}</span>
      </div>
      {props.sourcePosition && (
        <Handle type="source" position={props.sourcePosition} className="!border-none !bg-transparent" />
      )}

      {props.targetPosition && (
        <Handle type="target" position={props.targetPosition} className="!border-none !bg-transparent" />
      )}
    </div>
  );
};

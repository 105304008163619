import { BlockLoading } from '@burve-front/react-ui/src/components/loading/block-loading';
import { Suspense, isValidElement } from 'react';
import { Route, Switch } from 'wouter';
import { type RouteItem, routes } from './confs/route';
import TheGeneralLayout from './layouts/general';

const renderRoutes = (routes: RouteItem[]) => {
  return routes.map((item, index) => {
    const isElement = isValidElement(item.component);
    const Element = () => {
      return isElement ? (
        (item.component as JSX.Element)
      ) : (
        <Suspense fallback={item.fallback ?? <BlockLoading />}>
          {/* @ts-ignore */}
          <item.component />
        </Suspense>
      );
    };
    return (
      <Route key={`app-router-${index}`} path={item.path} component={Element}>
        {item.children && item.children.length > 0 ? renderRoutes(item.children) : null}
      </Route>
    );
  });
};

export const AppRouter = () => {
  return (
    <Route>
      <TheGeneralLayout>
        <Switch>{renderRoutes(routes)}</Switch>
      </TheGeneralLayout>
    </Route>
  );
};

import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import type { PublicKey } from '@solana/web3.js';
import { type UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

type Balance = {
  value: number;
  decimals: number;
};
type UseSolsanaBalanceProps = {
  publicKey?: PublicKey | null;
  query?:
    | Omit<UseQueryOptions<number | undefined>, 'queryFn' | 'queryHash' | 'queryKey' | 'queryKeyHashFn' | 'throwOnError'>
    | undefined;
};
export const useSolanaBalance = ({ publicKey, query }: UseSolsanaBalanceProps = {}) => {
  const { publicKey: walletPublicKey } = useWallet();
  const key = useMemo(() => publicKey || walletPublicKey, [publicKey, walletPublicKey]);
  const { connection } = useConnection();
  return useQuery({
    refetchInterval: false,
    ...query,
    queryKey: ['useSolanaBalance', key, connection?.rpcEndpoint],
    enabled: key != null && query?.enabled !== false,
    queryFn: async () => {
      if (!key) return;
      return await connection?.getBalance(key);
    },
    select(data) {
      if (!data) return;
      return {
        value: data,
        decimals: 9, // 1000000000
      } as Balance;
    },
  });
};

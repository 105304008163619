import { Pool } from '@/types/pool';

export const pools: Pool[] = [
  {
    id: '0x1',
    address: '0x1',
    name: 'DOG•GO•TO•THE•MOONWBTC',
    symbol: 'CIRUS',
    oneLogo: '/imgs/tokens/cirus.png',
    twoLogo: '/imgs/tokens/usdc.png',
    volume: 0,
    fees: 0,
    tvl: 0,
    pools: [
      {
        id: '0x1',
        address: '0x1',
        name: 'CIRUS',
        symbol: 'CIRUS',
        oneLogo: '/imgs/tokens/cirus.png',
        twoLogo: '/imgs/tokens/usdc.png',
        volume: 0,
        fees: 0,
        tvl: 0,
      },
    ],
  },
  {
    id: '0x2',
    address: '0x2',
    name: 'POL',
    symbol: 'POL',
    oneLogo: '/imgs/tokens/pol.png',
    twoLogo: '/imgs/tokens/usdt.png',
    volume: 0,
    fees: 0,
    tvl: 0,
    pools: [
      {
        id: '0x2',
        address: '0x2',
        name: 'POL',
        symbol: 'POL',
        oneLogo: '/imgs/tokens/pol.png',
        twoLogo: '/imgs/tokens/usdt.png',
        volume: 0,
        fees: 0,
        tvl: 0,
      },
    ],
  },
];

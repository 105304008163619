import type { SolanaChain } from '@burve-front/core';
import { ChainAvatar, cn } from '@burve-front/react-ui';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import type { Chain } from 'viem';

export type ChainProps = React.HTMLAttributes<HTMLElement> & {
  chain: Chain | SolanaChain;
  showName?: boolean;
  showTestnet?: boolean;
};

export const ChainInfo = ({ chain, showName = true, showTestnet = true, ...attrs }: ChainProps) => {
  const { t } = useTranslation();
  return (
    <div
      title={chain.name}
      {...attrs}
      className={cn('flex flex-nowrap items-center space-x-2 whitespace-nowrap text-left', attrs.className)}>
      <ChainAvatar chainName={chain.name} />
      {showName || showTestnet ? (
        <dl className="leading-none">
          {showName && <dt>{chain.name}</dt>}
          {showTestnet && chain.testnet && <dd className="text-[10px]">{t('testnet')}</dd>}
        </dl>
      ) : null}
    </div>
  );
};

import {
  type ChartOptions,
  ColorType,
  type DeepPartial,
  LineStyle,
  type SeriesDataItemTypeMap,
  type Time,
  createChart,
} from 'lightweight-charts';
import { useEffect, useRef } from 'react';
import { CustomHistogramSeries } from './custom-histogram-series';
import type { CustomHistogramData } from './custom-histogram-series/data';
import type { CustomHistogramSeriesOptions } from './custom-histogram-series/options';

type LiquidityChartProps = {
  series: {
    data: CustomHistogramData<number>[];
    options?: CustomHistogramSeriesOptions;
  }[];
  className?: string;
  options?: Omit<DeepPartial<ChartOptions>, 'width' | 'height'>;
};
const borderColor = '#49417c';
const textColor = '#9ca1b6';
const backgroundColor = 'transparent';
const customHistogramBarsView = new CustomHistogramSeries();
export const LiquidityChart = ({ series, className, options }: LiquidityChartProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!chartContainerRef.current) return;
    const handleResize = () => {
      if (!chartContainerRef.current) return;
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };
    const chart = createChart(chartContainerRef.current, {
      ...options,
      localization: {
        ...options?.localization,
        timeFormatter: (time: Time) => time.toString(),
      },
      layout: {
        textColor,
        attributionLogo: false,
        ...options?.layout,
        background: { type: ColorType.Solid, color: backgroundColor, ...options?.layout?.background },
      },
      crosshair: {
        ...options?.crosshair,
        vertLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.vertLine },
        horzLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.horzLine },
      },
      grid: {
        vertLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.vertLines },
        horzLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.horzLines },
      },
      leftPriceScale: { borderColor, ...options?.leftPriceScale, minimumWidth: 52 },
      rightPriceScale: { borderColor, ...options?.rightPriceScale, minimumWidth: 52 },
      timeScale: { borderColor, ...options?.timeScale, tickMarkFormatter: (time: Time) => time.toString() },
      height: chartContainerRef.current.clientHeight,
      width: chartContainerRef.current.clientWidth,
    });

    for (let i = 0; i < series.length; i++) {
      const serie = series[i];
      const customSeries = chart.addCustomSeries(customHistogramBarsView, {
        color: '#ff63fa',
        lastValueVisible: false,
        ...serie.options,
      });
      customSeries.setData(serie.data as SeriesDataItemTypeMap<Time>['Histogram'][]);
    }
    chart.timeScale().fitContent();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [series, options]);

  return <div ref={chartContainerRef} className={className} />;
};

import { tokens } from '@/apis/mocks';
import { TokenItem } from './token-item';

export const UpcomingContent = () => {
  const upcomingTokens = tokens.filter((token) => token.type === 'upcoming');
  return (
    <>
      {upcomingTokens.map((token) => {
        return <TokenItem key={token.address} token={token} />;
      })}
    </>
  );
};

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@burve-front/react-ui';
import { RoutesView } from '../routes-view';

type ConfirmInfoProps = {
  isOpen?: boolean;
};
export const ConfirmInfo = ({ isOpen }: ConfirmInfoProps) => {
  return (
    <Accordion defaultValue={isOpen ? ['item-1'] : []} type="multiple" className="bg-[#F8ECFD]/8 mt-2 rounded-lg px-2">
      <AccordionItem value="item-1" className="border-none">
        <AccordionTrigger className="h-10 rounded-lg py-2.25 text-sm outline-none text-content">
          <span>
            1 DAISY = 0.00018 USDT <span className="text-content-1">($0.00018)</span>
          </span>
        </AccordionTrigger>
        <AccordionContent className="border-t border-dashed border-color-[#9CA1B6] py-2.25 text-xs">
          <ul className="space-y-1">
            <li className="flex justify-between">
              <span className="text-content-1">Price impact</span>
              <span className="text-error">~1.023%</span>
            </li>
            <li className="flex justify-between">
              <span className="text-content-1">Tax</span>
              <span className="text-content">0.23 DAISY</span>
            </li>
            <li className="flex justify-between">
              <span className="text-content-1">Minimum receive</span>
              <span className="text-content">0.23 DAISY</span>
            </li>
            <li className="flex justify-between">
              <span className="text-content-1">Order routing</span>
              <RoutesView />
            </li>
          </ul>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

import useChain from '@/hooks/use-chain';
import { ProjectDownIcon, customToast } from '@burve-front/react-ui';
import {
  Button,
  CommonCheckmarkIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@burve-front/react-ui';
import { useTranslation } from 'react-i18next';
import { ChainInfo } from './chain';

export const SwitchChain = () => {
  const { t } = useTranslation();
  const { chain, chains, pendingChain, switchChain, isPending } = useChain();
  const changeNetwork = async (chainName?: string) => {
    if (chainName && chain.name !== chainName && !isPending) {
      await customToast.promise(switchChain(chainName), {
        loading: <b>{t('switch-chain-loading')}</b>,
        success: <b>{t('switch-chain-success')}</b>,
        error: (e) => (
          <b>
            {t('switch-chain-error')} {e?.shortMessage}
          </b>
        ),
      });
    }
  };

  if (chains.length <= 1) return null;
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="px-[10px]">
          <ChainInfo chain={isPending && pendingChain ? pendingChain : chain} showName={false} showTestnet={false} />
          <ProjectDownIcon className="ml-[6px] size-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {chains.map((item) => (
          <DropdownMenuItem
            key={`switch-chain-item-${item.id}`}
            onClick={() => changeNetwork(item.name)}
            className="h-10 cursor-pointer font-normal space-x-2">
            <ChainInfo chain={item} />
            {item.id === chain.id && <CommonCheckmarkIcon className="size-3 stroke-4 stroke-current" />}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

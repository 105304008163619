import { TEST } from '@/confs/app';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { QueryClient } from '@tanstack/react-query';
import localForage from 'localforage';
import { lazy } from 'react';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 2 * 60 * 1e3,
      staleTime: 1 * 60 * 1e3,
      // refetchInterval: 1 * 60 * 1e3,
      refetchOnReconnect: true,
      refetchOnMount: true,
      refetchOnWindowFocus: true,
      refetchIntervalInBackground: false,
    },
    mutations: {
      gcTime: 2 * 60 * 1e3,
    },
  },
});

export const storage = localForage.createInstance({ name: 'query' });

export const persister = createAsyncStoragePersister({ storage });

export const ReactQueryProductionDevtools = TEST
  ? lazy(() =>
      import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
        default: d.ReactQueryDevtools,
      })),
    )
  : null;

import { tokens } from '@/apis/mocks';
import { Button, ProjectFairIcon, ProjectMemeIcon, ProjectUpcomingIcon } from '@burve-front/react-ui';
import { TokenItem } from './token-item';

export const AllContent = () => {
  const hodlTokens = tokens.filter((token) => token.type === 'hodl').splice(0, 5);
  const LoLTokens = tokens.filter((token) => token.type === 'lol').splice(0, 5);
  const upcomingTokens = tokens.filter((token) => token.type === 'upcoming').splice(0, 5);
  return (
    <>
      {/* HODL */}
      <div className="mx-2 h-12 flex justify-between text-sm text-content-1">
        <span className="inline-flex items-center">
          <ProjectFairIcon className="mr-1 size-4 fill-current" />
          Fair Launch (HODL)
        </span>
        <Button variant="link" className="p-0">
          More
        </Button>
      </div>
      {hodlTokens.map((token) => {
        return <TokenItem key={token.address} token={token} />;
      })}
      {/* LOL */}
      <div className="mx-2 h-12 flex justify-between text-sm text-content-1">
        <span className="inline-flex items-center">
          <ProjectMemeIcon className="mr-1 size-4 fill-current" />
          MEME (LOL)
        </span>
        <Button variant="link" className="p-0">
          More
        </Button>
      </div>
      {LoLTokens.map((token) => {
        return <TokenItem key={token.address} token={token} />;
      })}
      {/* Upcoming */}
      <div className="mx-2 h-12 flex justify-between text-sm text-content-1">
        <span className="inline-flex items-center">
          <ProjectUpcomingIcon className="mr-1 size-4 fill-current" />
          Upcoming Listing
        </span>
        <Button variant="link" className="p-0">
          More
        </Button>
      </div>
      {upcomingTokens.map((token) => {
        return <TokenItem key={token.address} token={token} />;
      })}
    </>
  );
};

import { tokens } from '@/apis/mocks';
import { TokenItem } from './token-item';

export const LoLContent = () => {
  const LoLTokens = tokens.filter((token) => token.type === 'lol');
  return (
    <>
      {LoLTokens.map((token) => {
        return <TokenItem key={token.address} token={token} />;
      })}
    </>
  );
};

import { APP_DIR, APP_I18N } from '@/confs/app';
import { setLocale } from '@burve-front/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

// save key
export const LocaleSaveKey = 'locale';
export type Loclae = { name: string; lang: string };
export type TDirection = 'ltr' | 'rtl';
export interface ILocaleState {
  locale: string;
  direction: TDirection;
  setDirection: (newDirection: TDirection) => void;
  setLocale: (newLocale: string) => void;
}
// store
export const useLocaleStore = createWithEqualityFn<ILocaleState>()(
  persist(
    (set, get) => ({
      locale: APP_I18N.defaultLocale,
      direction: APP_DIR,
      setDirection(newDirection: TDirection) {
        const { direction } = get();
        if (direction !== newDirection) set({ direction: newDirection });
      },
      setLocale(newLocale: string) {
        const { locale } = get();
        const result = locale !== newLocale ? APP_I18N.locales.find((item) => item.lang === newLocale) : null;
        if (result) set({ locale: result.lang });
      },
    }),
    { name: LocaleSaveKey },
  ),
  shallow,
);

export type useLocaleProps = Record<string, never>;
const useLocale = () => {
  const { locale, setLocale: switchLocale, direction, setDirection } = useLocaleStore((state) => ({ ...state }));
  const { i18n, ready } = useTranslation();

  useEffect(() => {
    if (ready) {
      i18n.changeLanguage(locale);
    }
    setLocale(locale, direction);
  }, [ready, i18n, locale, direction]);

  return {
    locale,
    locales: APP_I18N.locales,
    switchLocale,
    switchDirection: setDirection,
  };
};

export default useLocale;

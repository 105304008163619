import useChain from '@/hooks/use-chain';
import { useModalStore } from '@/store/modal-store';
import { EChainType } from '@burve-front/core';
import { Dialog, DialogContent, DialogTitle, Tabs, TabsContent, TabsList, TabsTrigger } from '@burve-front/react-ui';

import { useTranslation } from 'react-i18next';
import { EvmWallets } from './evm-wallets';
import { SolansAdapters } from './solana-adapters';

export const SwitchWalletDialog = () => {
  const { t } = useTranslation();
  const setIsConnectModalOpen = useModalStore((state) => state.setIsConnectModalOpen);
  const isConnectModalOpen = useModalStore((state) => state.isConnectModalOpen);
  const { chainType } = useChain();
  return (
    <Dialog open={isConnectModalOpen} onOpenChange={(open) => setIsConnectModalOpen(open)}>
      <DialogContent className="max-w-xs">
        <DialogTitle className="text-center">{t('connect-wallet')}</DialogTitle>
        <Tabs defaultValue={chainType} className="mt-6">
          <TabsList className="mb-2 w-full">
            <TabsTrigger value={EChainType.Evm} className="w-1/2">
              {t('evm')}
            </TabsTrigger>
            <TabsTrigger value={EChainType.Solana} className="w-1/2">
              {t('solana')}
            </TabsTrigger>
          </TabsList>
          <TabsContent value={EChainType.Evm}>
            <EvmWallets />
          </TabsContent>
          <TabsContent value={EChainType.Solana}>
            <SolansAdapters />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

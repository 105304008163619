import { cn } from '@/utils';
import { useWatchNowDate } from '@burve-front/react-sdk';
import { type Duration, differenceInDays, differenceInMilliseconds, intervalToDuration } from 'date-fns';
import { format } from 'date-fns/format';
import { useEffect, useMemo, useState } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';
type CountdownProps = {
  className?: string;
  endDate?: Date;
  curation?: Duration;
  onUpdate?: (nowDate: Date) => void;
};

export const Countdown = ({ className, curation, endDate, onUpdate }: CountdownProps) => {
  const [formattedDuration, setFormattedDuration] = useState<Duration | undefined>(curation);
  const days = useMemo(
    () => (endDate ? Math.max(differenceInDays(endDate, new Date()), 0) : formattedDuration?.days),
    [formattedDuration?.days, endDate],
  );
  const tip = useMemo(() => {
    const formatStr = endDate && endDate.getFullYear() > new Date().getFullYear() ? 'MMM dd yyyy HH:mm' : 'MMM dd, HH:mm';
    return endDate ? format(endDate?.getTime(), formatStr) : '';
  }, [endDate]);
  const [watch, setWatch] = useState(() => curation === undefined);
  const [isEnd, setEnd] = useState(() => (endDate ? endDate <= new Date() : true));
  useWatchNowDate({
    step: 1e3,
    watch,
    onUpdate(nowDate) {
      onUpdate?.(nowDate);
      if (endDate) {
        const end = differenceInMilliseconds(endDate?.getTime(), nowDate.getTime());
        setFormattedDuration(intervalToDuration({ start: 0, end }));
        if (endDate <= nowDate) {
          setWatch(false);
          setEnd(true);
        } else {
          setEnd(false);
        }
      }
    },
  });

  useEffect(() => {
    const end = endDate
      ? intervalToDuration({ start: 0, end: differenceInMilliseconds(endDate, new Date().getTime()) })
      : curation;
    setFormattedDuration(end);
  }, [endDate, curation]);

  if (isEnd) return '-';
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className={cn('inline-flex whitespace-nowrap text-sm', className)} data-tip={tip}>
            {days !== undefined && days > 0 && <>{days} day</>}
            {formattedDuration?.hours !== undefined && (days === undefined || days <= 0) && `${formattedDuration.hours}h:`}
            {formattedDuration?.minutes !== undefined &&
              (days === undefined || days <= 0) &&
              `${formattedDuration.minutes}m:`}
            {(days === undefined || days <= 0) && `${formattedDuration?.seconds ?? 0}s`}
          </span>
        </TooltipTrigger>
        <TooltipContent>{tip}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

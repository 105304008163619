import useCheckActiveNav from '@/hooks/use-check-active-nav';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  ProjectArrowUpRightIcon,
  cn,
} from '@burve-front/react-ui';
import { ChevronDown } from 'lucide-react';
import { type ReactNode, useCallback, useMemo } from 'react';
import { Link } from 'wouter';

export type NavItem = {
  show?: boolean;
  label: ReactNode;
  icon?: ReactNode;
} & ({ href: string; sub?: never } | { href?: never; sub: NavItem[] });

export type NavItemProps<T extends NavItem = NavItem> = {
  item: T;
  className?: string;
  type?: 'col' | 'row';
  showIcon?: boolean;
  showLabel?: boolean;
};

const NavCollapsibleItem = ({ className, type = 'col', showIcon = false, showLabel = true, item }: NavItemProps) => {
  const { checkActiveNav } = useCheckActiveNav();
  const active = useMemo(() => item.sub?.some((s) => checkActiveNav(s.href as string)), [checkActiveNav, item.sub]);
  return (
    <Collapsible defaultOpen={active}>
      <CollapsibleTrigger
        className={cn(
          'flex items-center px-2 rounded cursor-pointer justify-between hover:text-content text-content-1 whitespace-nowrap',
          active && 'text-content opacity-100 font-bold',
          active && type === 'col' && 'bg-background',
          type === 'col' ? 'w-full h-8 md:h-10' : '',
          className,
        )}>
        <span className="inline-flex space-x-2">
          {showIcon && <span>{item.icon}</span>}
          {showLabel && <span>{item.label}</span>}
        </span>
        <ChevronDown className="ml-1 size-4 shrink-0" />
      </CollapsibleTrigger>
      <CollapsibleContent className="ml-5 border-l py-2">
        {item.sub?.map((sub) => (
          <NavItem
            key={`app-nav-${sub.href}`}
            item={sub}
            className={className}
            type={type}
            showIcon={showIcon}
            showLabel={showLabel}
          />
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
};

const NavDropdownMenuItem = ({ className, type = 'col', showIcon = false, showLabel = true, item }: NavItemProps) => {
  const { checkActiveNav } = useCheckActiveNav();
  const active = useMemo(() => item.sub?.some((s) => checkActiveNav(s.href as string)), [checkActiveNav, item.sub]);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger
        className={cn(
          'flex items-center px-2 rounded cursor-pointer justify-center hover:text-content text-content-1 whitespace-nowrap',
          active && 'text-content opacity-100 font-bold',
          active && type === 'col' && 'bg-background',
          type === 'col' ? 'w-full h-8 md:h-10' : '',
          className,
        )}>
        <span className="inline-flex space-x-2">
          {showIcon && <span>{item.icon}</span>}
          {showLabel && <span>{item.label}</span>}
        </span>
        {type === 'row' && <ChevronDown className="ml-1 size-4 shrink-0" />}
      </DropdownMenuTrigger>
      <DropdownMenuContent side={type === 'col' ? 'right' : 'bottom'} align="start" sideOffset={5} className="px-2">
        {type === 'col' && (
          <>
            <DropdownMenuLabel>{item.label}</DropdownMenuLabel>
            <DropdownMenuSeparator />
          </>
        )}
        {item.sub?.map((sub) => (
          <DropdownMenuItem key={`app-nav-${sub.href}`} asChild className="cursor-pointer">
            <NavItem item={sub} className={className} type={type} showIcon={true} showLabel={true} />
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const NavLinkItem = ({ className, type = 'col', showIcon = false, showLabel = true, item }: NavItemProps) => {
  const isHttp = useMemo(() => item.href?.startsWith('http'), [item.href]);
  const classNames = useCallback(
    (active: boolean) => {
      return cn(
        'flex items-center px-2 cursor-pointer rounded hover:text-content text-content-1 whitespace-nowrap  font-normal',
        active && 'text-content opacity-100 font-400',
        active && type === 'col' && 'bg-background',
        type === 'col' ? 'w-full h-8 md:h-10' : '',
        showLabel && showIcon ? 'space-x-2 justify-start' : 'justify-center',
        className,
      );
    },
    [className, showIcon, showLabel, type],
  );
  const props = useMemo(() => {
    return {
      href: item.href as string,
      target: isHttp ? '_blank' : undefined,
      rel: isHttp ? 'noreferrer noopener' : undefined,
      className: isHttp ? classNames(false) : classNames,
    };
  }, [classNames, isHttp, item.href]);
  const Comp = useMemo(() => (isHttp ? 'a' : Link), [isHttp]);
  return (
    // @ts-ignore
    <Comp {...props}>
      {showIcon && <span>{item.icon}</span>}
      {showLabel && <span>{item.label}</span>}
      {isHttp && <ProjectArrowUpRightIcon className="ml-1 size-[9px]" />}
    </Comp>
  );
};

export const NavItem = (props: NavItemProps) => {
  if (props.item.sub) {
    if (props.showLabel && props.type === 'col') {
      return <NavCollapsibleItem {...props} />;
    }
    return <NavDropdownMenuItem {...props} />;
  }
  return <NavLinkItem {...props} />;
};

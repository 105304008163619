import { combine } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type ViewMode = 'input' | 'setting';
// store
export const useSwapStore = createWithEqualityFn(
  combine(
    {
      viewMode: 'input',
      slippage: 3, // range 0-100;
    },
    (set) => ({
      setViewMode: (viewMode: ViewMode) => set({ viewMode }),
      setSlippage: (slippage: number) => set({ slippage }),
    }),
  ),
  shallow,
);

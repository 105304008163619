import { ProjectArrowDownIcon, ProjectSwitchIcon } from '@burve-front/react-ui';

export const Switch = () => {
  return (
    <div className="absolute left-1/2 top-1/2 mt-[-20px] -ml-[20px] z-10">
      <div className="size-10  shadow-md cursor-pointer overflow-hidden [&>div]:hover:translate-y-[-15px] justify-center border border-white/30 before:content-[''] before:absolute before:rounded-full before:size-full bg-[#2e2648] before:bg-[linear-gradient(0deg,rgba(154,77,255,0.8)_0%,rgba(154,77,255,0)_100%,#2e2648)] rounded-full">
        <div className="transition-all duration-200 flex flex-col items-center translate-y-[15px] space-y-[15px] text-content">
          <ProjectArrowDownIcon className="w-[15px] h-3 fill-current" />
          <ProjectSwitchIcon className="w-[15px] h-3 rotate-90 fill-current" />
        </div>
      </div>
    </div>
  );
};

import { useEffect, useMemo } from 'react';
import ReactFlow, {
  type Node,
  type Edge,
  Position,
  MarkerType,
  useNodesState,
  useEdgesState,
  type NodeTypes,
  type EdgeTypes,
} from 'reactflow';
import { CustomEdge, type EdgeData } from './router-custom-edge';
import { CustomNode, type NodeData } from './router-custom-node';
import 'reactflow/dist/style.css';
import { ProjectLogoIcon } from '@burve-front/react-ui';

export type RouteItem = {
  icon: string;
  symbol: string;
  rate: string;
  rateSymbol: string;
};

const nodeTypes: NodeTypes = {
  custom: CustomNode,
};

const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
};

export const RouterFlow = () => {
  const initNodes: Node<NodeData>[] = useMemo(
    () => [
      {
        id: '1',
        type: 'custom',
        data: { icon: ProjectLogoIcon, symbol: 'USDT' },
        position: { x: 0, y: 0 },
        sourcePosition: Position.Bottom,
      },
      {
        id: '2',
        type: 'custom',
        data: { icon: ProjectLogoIcon, symbol: 'USDT' },
        position: { x: 185, y: 60 },
        targetPosition: Position.Left,
        sourcePosition: Position.Right,
      },
      {
        id: '3',
        type: 'custom',
        data: { icon: ProjectLogoIcon, symbol: 'USDT' },
        position: { x: 360, y: 0 },
        targetPosition: Position.Bottom,
      },
    ],
    [],
  );
  const initEdges: Edge<EdgeData>[] = useMemo(
    () => [
      {
        id: 'e1-2',
        animated: true,
        type: 'custom',
        style: { dash: { length: 5, gap: 5 }, stroke: '#6D6D78' },
        source: '1',
        target: '2',
        pathOptions: {
          borderRadius: 20,
        },
        markerEnd: {
          type: MarkerType.Arrow,
          color: '#D6D6D6',
          width: 20,
          height: 20,
          strokeWidth: 2,
        },
        data: {
          topTip: `tax rate: 10%`,
          bottomTip: `~10`,
        },
      },
      {
        id: 'e2-3',
        animated: true,
        type: 'custom',
        arrowHeadType: 'arrow',
        style: { dash: { length: 5, gap: 5 }, stroke: '#6D6D78' },
        source: '2',
        target: '3',
        pathOptions: {
          borderRadius: 20,
        },
        markerEnd: {
          type: MarkerType.Arrow,
          color: '#D6D6D6',
          width: 20,
          height: 20,
          strokeWidth: 2,
        },
        data: {
          topTip: `tax rate: 10%`,
          bottomTip: `~10`,
        },
      },
    ],
    [],
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes); //  ==> setNodes
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges); // ==> setEdges
  useEffect(() => {
    setNodes(initNodes);
    setEdges(initEdges);
  }, [initNodes, initEdges, setNodes, setEdges]);
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      panOnScroll={true}
      zoomOnPinch={true}
      maxZoom={1.5}
      minZoom={0.5}
      zoomOnScroll={true}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodeOrigin={[0.5, 0.5]}
      proOptions={{ hideAttribution: true }}
      fitView
      className="w-full h-[160px]"
    />
  );
};

import { cn } from '@/utils';
import { type VariantProps, cva } from 'class-variance-authority';
import type * as React from 'react';

const badgeVariants = cva(
  'inline-flex items-center rounded-1 px-2.25 text-sm font-medium transition-colors focus:outline-none',
  {
    variants: {
      variant: {
        default: 'bg-[linear-gradient(180deg,rgba(121,125,149,0.5)_0%,rgba(121,125,149,0.22)_100%)]',
        primary: 'bg-[linear-gradient(0deg,rgba(154,77,255,0.8)_0%,rgba(154,77,255,0)_100%)] border',
        primary500: 'bg-[linear-gradient(0deg,rgba(135,102,255,0.78)_-15%,#9A4DFF_100%),linear-gradient(0deg,_rgba(154,77,255,0.8)_0%,rgba(154,77,255,0)_100%)]',
        outline: 'text-content',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };

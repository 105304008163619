import { docsInfo, media } from '@/confs/app';
import { DivideHorizontal, ProjectLogoIcon } from '@burve-front/react-ui';
import { useTranslation } from 'react-i18next';

const TheGeneralFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="w-full pt-8">
      <DivideHorizontal />
      <div className="mx-auto h-16 max-w-[1500px] flex items-center justify-center px-2 py-4 text-sm text-content-1 container !mt-0 lg:justify-between">
        <div className="hidden items-center justify-center lg:flex space-x-3">
          <ProjectLogoIcon className="size-10 fill-current" />
          <div className="flex items-center">
            {docsInfo.map((item, index) => {
              return (
                <div key={item.label} className="flex items-center">
                  {index > 0 && <div className="block size-[3px] rounded-full bg-content-1" />}
                  <a
                    href={item.path}
                    aria-label={item.label}
                    target="_blank"
                    className="px-2 hover:text-content"
                    rel="noreferrer">
                    {t(item.label)}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex items-center space-x-7">
          {media.map((item, index) => {
            return (
              <a key={index} aria-label={item.path} href={item.path} target="_blank" rel="noreferrer">
                <item.icon className="size-4 fill-current text-current hover:fill-content" />
              </a>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default TheGeneralFooter;

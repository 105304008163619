import { AdBanner } from '@/components/ad-banner';
import { ConnectWallet } from '@/components/connect-wallet';
import { Nav } from '@/components/nav';
import { SwitchChain } from '@/components/switch-chain';
import { SwitchLocale } from '@/components/switch-locale';
import { Title } from '@/components/title';
import { DivideHorizontal } from '@burve-front/react-ui';
import { useMedia } from 'react-use';
const TheGeneralHeader = () => {
  const isMobile = useMedia('(max-width: 767px)');
  return (
    <header className="sticky top-0 z-10 w-full bg-[#120621]/50 backdrop-blur">
      <AdBanner />
      <DivideHorizontal />
      <div className="relative mx-auto h-15 flex items-center justify-between px-2 container 2xl:max-w-[1500px] md:h-17">
        <Title />
        {!isMobile && <Nav type="row" className="md:ml-24" />}
        <div className="flex items-center space-x-2">
          <SwitchLocale />
          <SwitchChain />
          <ConnectWallet />
        </div>
      </div>
      <DivideHorizontal />
    </header>
  );
};

export default TheGeneralHeader;

import useModalStore from '@/store/modal-store';
import {
  AddressView,
  CommonSuccessCircleIcon,
  CommonWarningCircleIcon,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  ProjectArrowRightIcon,
  ProjectArrowRightUpIcon,
  UserAvatar,
} from '@burve-front/react-ui';
import { useAccount } from 'wagmi';
export const RecentTransactionDialog = () => {
  const setIsRecentModalOpen = useModalStore((state) => state.setIsRecentModalOpen);
  const isRecentModalOpen = useModalStore((state) => state.isRecentModalOpen);
  const { address } = useAccount();
  return (
    <Dialog open={isRecentModalOpen} onOpenChange={(open) => setIsRecentModalOpen(open)}>
      <DialogContent
        style={{}}
        className="top-[30%] max-w-[427px] border rounded-md bg-background-1 after:hidden before:hidden">
        <DialogHeader>
          <DialogTitle className="flex items-center text-sm text-content-1 space-x-2">
            <UserAvatar address={address} className="size-6" />
            <AddressView address={address} />
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-sm text-content-3">
          <div className="my-3 text-left text-6 text-white">$123.45</div>
          <div className="mb-2 flex items-center justify-between">
            <span>Recent transaction</span>
            <ProjectArrowRightIcon className="h-2 w-2.5 fill-content-1" />
          </div>
          {/* items */}
          <ul className="mix-h-[504px] overflow-x-hidden overflow-y-auto">
            <li className="h-12 flex items-center justify-between">
              <div className="flex items-center">
                <CommonSuccessCircleIcon className="mr-1.5 size-4 fill-success stroke-success" />
                <div className="max-w-[260px] flex-1 truncate text-left md:max-w-[320px]">
                  Swap 100000000000 DAISY for 0.237 BNB
                </div>
              </div>
              <a href="" target="_blank">
                <ProjectArrowRightUpIcon className="size-4 cursor-pointer fill-content-1 hover:fill-white" />
              </a>
            </li>
            <li className="h-12 flex items-center justify-between">
              <div className="flex items-center">
                <CommonWarningCircleIcon className="mr-1.5 size-4 fill-error stroke-error" />
                <div className="max-w-[260px] flex-1 truncate text-left md:max-w-[320px]">
                  Swap 100000000000 DAISY for 0.237 BNB
                </div>
              </div>
              <a href="" target="_blank">
                <ProjectArrowRightUpIcon className="size-4 cursor-pointer fill-content-1 hover:fill-white" />
              </a>
            </li>
          </ul>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
};

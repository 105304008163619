import { pools } from '@/apis/mocks';
import { PoolItem } from './pool-item';

export const AllPool = () => {
  return (
    <>
      {pools.map((pool) => (
        <PoolItem key={pool.address} pool={pool} />
      ))}
    </>
  );
};

import { cn } from '@/utils';
import { useMemo } from 'react';
import { CommonUpIcon } from '../../assets';
import { NumberView, type NumberViewProps } from './number-view';

type RiseViewProps = NumberViewProps & {
  type?: 'badge' | 'text';
  showArrow?: boolean;
  multiple?: number;
};
export const RiseView = ({
  type = 'text',
  showArrow = true,
  number,
  className,
  before = '',
  after = '%',
  multiple = 2,
  decimal = 2,
  children,
  ...attrs
}: RiseViewProps) => {
  const classNames = useMemo(() => {
    if (type === 'badge') {
      return cn('text-white rounded-full', number > 0 ? 'bg-success' : 'bg-error', className);
    }
    return number > 0 ? 'text-success' : 'text-error';
  }, [className, number, type]);
  return (
    <NumberView
      {...attrs}
      decimal={decimal}
      before={`${before}${number > 0 ? '+' : number === 0 ? '' : '-'}`}
      number={Number.isNaN(number) ? number : 10 ** multiple * Math.abs(number)}
      after={after}
      className={cn('space-x-1 text-sm', classNames, className)}
      afterClassName="ml-0">
      {showArrow && <CommonUpIcon className={cn('inline size-2', number > 0 ? 'fill-success' : 'fill-error rotate-180')} />}
      <span>{children}</span>
    </NumberView>
  );
};

import { cn } from '@/utils';
import { type SolanaChain, fmtAddress } from '@burve-front/core';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useCopyToClipboard } from 'react-use';
import { type Address, type Chain, zeroAddress } from 'viem';
import { CommonCopyIcon, CommonShareIcon, CommonSuccessCircleIcon } from '../../assets';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';

type AddressViewProps = {
  chain?: Chain | SolanaChain;
  className?: string;
  text?: ReactNode | undefined;
  address?: Address | string;
  showTip?: boolean;
  showCopy?: boolean;
  showShare?: boolean;
  target?: boolean;
  shareUrl?: string;
  type?: 'address' | 'tx' | 'token';
  before?: number;
  after?: number;
  showZero?: boolean;
};
// address
export const AddressView = ({
  chain,
  className,
  text,
  address,
  target = false,
  showTip = true,
  showCopy = true,
  showShare = false,
  showZero = false,
  shareUrl,
  type = 'address',
  before,
  after,
}: AddressViewProps) => {
  const [state, copyToClipboard] = useCopyToClipboard();
  const [show, setShow] = useState(false);
  const [targetUrl, setTargetUrl] = useState(shareUrl);
  const isZero = useMemo(() => address && address.toLowerCase() === zeroAddress, [address]);
  useEffect(() => {
    if (state.value) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
      }, 1000);
      return () => {
        setShow(false);
        clearTimeout(timer);
      };
    }
    return undefined;
  }, [state]);

  useEffect(() => {
    if (chain?.blockExplorers && !shareUrl && type) {
      const url = new URL(chain.blockExplorers.default.url);
      url.pathname += `/${type}/${address}`;
      setTargetUrl(url.href);
    }
  }, [address, chain, shareUrl, type]);
  if (!address || (isZero && !showZero)) return null;
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className={cn('inline-flex items-center', className)}>
            {target ? (
              <a href={targetUrl} target="_blank" aria-label={address} rel="noreferrer" className="hover:underline">
                {text ? text : fmtAddress(address, before, after)}
              </a>
            ) : (
              <span>{text ? text : fmtAddress(address, before, after)}</span>
            )}
            {showCopy && show && (
              <CommonSuccessCircleIcon className="!fill-success !stroke-success size-[0.8em] ml-1 cursor-pointer" />
            )}
            {showCopy && !show && (
              <CommonCopyIcon
                className="h-[0.8em] w-[1em] cursor-pointer fill-current ml-1 text-current hover:scale-105 hover:fill-primary hover:text-primary"
                onClick={() => copyToClipboard(address)}
              />
            )}
            {showShare && targetUrl && (
              <a
                href={targetUrl}
                target="_blank"
                rel="noreferrer"
                className="text-current hover:scale-105 hover:text-primary ml-1">
                <CommonShareIcon className="size-[0.8em] cursor-pointer fill-current text-current" />
              </a>
            )}
          </span>
        </TooltipTrigger>
        <TooltipContent hidden={!showTip}>{address}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import { APP_I18N, TEST } from '@/confs/app';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './lang/en/translation.json';
import zhTranslation from './lang/zh/translation.json';

export const i18n = i18next.createInstance();
i18n.use(initReactI18next).init({
  lng: APP_I18N.defaultLocale,
  fallbackLng: APP_I18N.defaultLocale,
  supportedLngs: APP_I18N.locales.map((item) => item.lang),
  debug: TEST,
  resources: {
    en: {
      translation: enTranslation,
    },
    zh: {
      translation: zhTranslation,
    },
  },
  interpolation: { escapeValue: false },
});

import { cn } from '@/utils';
import { fmtAmount, fmtSafeToString } from '@burve-front/core';
import type { PropsWithChildren } from 'react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';
import { SpanLoading } from '../loading/span-loading';

export type NumberViewProps = PropsWithChildren & {
  className?: string;
  number: any;
  decimal?: number;
  truncate?: boolean;
  isLoading?: boolean;
  before?: string;
  after?: string;
  showTip?: boolean;
  afterClassName?: string;
};

export const NumberView = ({
  number,
  decimal,
  truncate,
  before = '',
  after = '',
  isLoading,
  showTip = true,
  children,
  afterClassName,
  ...attrs
}: NumberViewProps) => {
  return (
    <TooltipProvider delayDuration={200}>
      <Tooltip>
        <TooltipTrigger asChild>
          <span className={cn('whitespace-nowrap inline-flex', attrs.className)}>
            {isLoading ? (
              <SpanLoading />
            ) : (
              <>
                <span>{children}</span>
                <span className="inline-flex items-end">
                  {before}
                  {fmtAmount(number, decimal, Math.floor, truncate)[0]}
                  {after && <span className={cn('ml-1 inline-block max-w-[80px] truncate', afterClassName)}>{after}</span>}
                </span>
              </>
            )}
          </span>
        </TooltipTrigger>
        <TooltipContent hidden={!showTip}>
          {isLoading ? '-' : `${before}${Number.isNaN(number) ? '-' : fmtSafeToString(number)}${after}`}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import { getSolanaChain } from '@/utils/solana';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

export enum EChainType {
  Evm = 'EVM',
  Solana = 'Solana',
}

export const solana = getSolanaChain({
  id: 1e9,
  name: 'Solana',
  network: WalletAdapterNetwork.Mainnet,
});

export const solanaDevnet = getSolanaChain({
  id: 1e9 + 1,
  name: 'SolanaDevnet',
  network: WalletAdapterNetwork.Devnet,
  testnet: true,
});

export const solanaTestnet = getSolanaChain({
  id: 1e9 + 2,
  name: 'SolanaTestnet',
  network: WalletAdapterNetwork.Testnet,
  testnet: true,
});

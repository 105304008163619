import { solana, solanaDevnet } from '@burve-front/core';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { PhantomWalletAdapter, SolflareWalletAdapter } from '@solana/wallet-adapter-wallets';
import { TEST } from './app';

export const SOLANA_NETWORKS = [WalletAdapterNetwork.Devnet, WalletAdapterNetwork.Mainnet, WalletAdapterNetwork.Testnet];
export const SOLANA_DEFAULT_NETWORK = SOLANA_NETWORKS[0];
export const SOLANA_ADAPTERS = [new PhantomWalletAdapter(), new SolflareWalletAdapter()];

export const SOLANA_CHAINS = TEST ? [solana, solanaDevnet] : [solana];
export const SOLANA_DEFAULT_CHAIN = SOLANA_CHAINS[0];

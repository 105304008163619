import { combine } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

// store
export const useAdStore = createWithEqualityFn(
  combine(
    {
      isCloseAd: false,
    },
    (set) => ({
      setIsCloseAd(isCloseAd: boolean) {
        set({ isCloseAd });
      },
    }),
  ),
  shallow,
);

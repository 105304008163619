import { SpanLoading } from '@/components/loading/span-loading';
import { cn } from '@/utils';
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import * as React from 'react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded font-normal transition-colors duration-300 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-[#16162e] bg-opacity-60 border border-[#acb0c8] border-opacity-50 hover:bg-opacity-100 hover:border-opacity-100',
        primary: 'bg-[radial-gradient(42%_40%_at_50%_92%,#C09BED,#9A4DFF)] border-transparent border hover:border-[#AB79E8]',
        secondary:
          'border-[#C09BED] border bg-clip-text text-transparent bg-[linear-gradient(180deg,#FFFFFF_0%,#D5BCF3_100%)]',
        outline: 'border border-[#acb0c8]/50',
        ghost: 'hover:bg-[#16162e]',
        accent: 'bg-[linear-gradient(0deg,rgba(154,77,255,0.8)_0%,rgba(154,77,255,0)_100%)] border border-[#AB79E8]',
        link: 'text-primary hover:underline',
      },
      size: {
        default: 'h-8 py-[5px] px-3',
        sm: 'h-6 py-1 px-2',
        lg: 'h-10 py-2 px-[5px]',
        icon: 'p-[5px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, isLoading = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
        {isLoading && <SpanLoading />}
        {asChild ? <></> : children}
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };

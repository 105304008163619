import { pools } from '@/apis/mocks';
import { chartData1, liquidityData } from '@/apis/mocks/chart';
import { Swap } from '@burve-front/react-swap';
import {
  AddressView,
  AreaChart,
  Avatar,
  Badge,
  Button,
  DivideVertical,
  LargeSmallAvatar,
  LiquidityChart,
  NumberView,
  PairAvatar,
  ProjectArrowLeftIcon,
  ProjectScanIcon,
  ProjectShareIcon,
  ProjectStatsIcon,
  ProjectSwitchIcon,
  ProjectTransactionIcon,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '@burve-front/react-ui';
import { useLocation, useParams } from 'wouter';
import { TheErrorPage } from '../error';
import { Transaction } from './components/transaction';

export const ThePoolDetailPage = () => {
  const { address } = useParams();
  const pool = pools.find((pool) => pool.address === address);
  const [, navigate] = useLocation();
  const handleBack = () => {
    if (history.length > 1) {
      history.go(-1);
    } else {
      navigate('/pools');
    }
  };
  if (!pool) {
    return <TheErrorPage />;
  }
  return (
    <div className="mx-auto max-w-[1100px] w-full pb-3.5 pt-4">
      <Button
        variant="link"
        onClick={() => handleBack}
        className="cursor-pointer p-0 text-sm text-content underline-transparent hover:-translate-x-1">
        <ProjectArrowLeftIcon className="mr-1 h-2 w-2.5 fill-content-1" />
        Pools
      </Button>
      <div className="flex justify-between space-x-6">
        {/* left */}
        <div className="mt-5 flex-1 flex-shrink-0">
          {/* avatar */}
          <div className="w-full flex items-center justify-between">
            <div className="flex items-center justify-between space-x-1">
              <PairAvatar one={pool.oneLogo} two={pool.twoLogo} />
              <dl>
                <dt className="flex items-center text-5 space-x-2">
                  <div className="max-w-[270px] truncate whitespace-nowrap">{pool.symbol}</div>
                  <span>-</span>
                  <span>USDC</span>
                  <Badge className="h-5 text-xs text-success">+0.36%</Badge>
                </dt>
                <dd className="text-sm text-content-2">ID: 5430300</dd>
              </dl>
            </div>
            <div>
              <Button size="icon" className="bg-opacity-30 text-xs">
                <ProjectShareIcon className="mr-1 size-2.5" />
                share
              </Button>
            </div>
          </div>
          <Tabs defaultValue="price">
            {/* price */}
            <div className="mt-5 w-full flex justify-between">
              <div className="space-x-2">
                <span className="text-6 font-500">0.80322</span> <span className="text-sm">DAISY per ETH</span>
                <span className="text-content-1">($125.156)</span>
              </div>
              <TabsList className="mb-2 space-x-1">
                <TabsTrigger value="price" size="sm">
                  Price
                </TabsTrigger>
                <TabsTrigger value="liquidity" size="sm">
                  Liquidity
                </TabsTrigger>
              </TabsList>
            </div>
            {/* chart */}
            <div className="mt-4">
              <TabsContent value="price">
                <AreaChart series={[{ data: chartData1 }]} className="h-[480px] w-full" />
              </TabsContent>
              <TabsContent value="liquidity">
                <LiquidityChart series={[{ data: liquidityData }]} className="h-[480px] w-full" />
              </TabsContent>
            </div>
          </Tabs>
        </div>
        {/* right */}
        <div className="w-[440px] space-y-[30px]">
          <div className="border-gradient-card w-full px-4 text-xs divide-y divide-dashed">
            <div className="flex items-center justify-between py-3">
              <div className="flex items-center">
                <LargeSmallAvatar large={pool.oneLogo} small={pool.twoLogo} />
                <span className="ml-1 text-sm">{pool.symbol} / USDC</span>
              </div>
              <div className="flex items-center space-x-3">
                <AddressView
                  address={pool.address}
                  showCopy
                  className="h-6 rounded-[4px] bg-[#16162E]/30 px-2 text-content-1"
                />
                <Button size="icon" className="border-none bg-opacity-30">
                  <ProjectScanIcon />
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-between py-3">
              <div className="flex items-center">
                <Avatar avatar={pool.oneLogo} />
                <span className="ml-1 text-sm">{pool.symbol}</span>
              </div>
              <div className="flex items-center space-x-3">
                <AddressView
                  address={pool.address}
                  showCopy
                  className="h-6 rounded-[4px] bg-[#16162E]/30 px-2 text-content-1"
                />
                <Button size="icon" className="border-none bg-opacity-30">
                  <ProjectScanIcon />
                </Button>
              </div>
            </div>
            <div className="flex items-center justify-between py-3">
              <div className="flex items-center">
                <Avatar avatar={pool.twoLogo} />
                <span className="ml-1 text-sm">USDC</span>
              </div>
              <div className="flex items-center space-x-3">
                <AddressView
                  address={pool.address}
                  showCopy
                  className="h-6 rounded-[4px] bg-[#16162E]/30 px-2 text-content-1"
                />
                <Button size="icon" className="border-none bg-opacity-30">
                  <ProjectScanIcon />
                </Button>
              </div>
            </div>
          </div>
          <Swap />
        </div>
      </div>
      {/* metadata */}
      <div className="mt-10 w-full">
        <Tabs defaultValue="stats">
          <TabsList variant="primary" className="mb-2 space-x-3">
            <TabsTrigger variant="primary" value="stats">
              <ProjectStatsIcon className="mr-2 size-4" />
              Stats
            </TabsTrigger>
            <TabsTrigger variant="primary" value="transaction">
              <ProjectTransactionIcon className="mr-2 size-4" />
              Transaction
            </TabsTrigger>
          </TabsList>
          <TabsContent value="transaction">
            <Transaction pool={pool} />
          </TabsContent>
          <TabsContent value="stats">
            <div className="border-gradient-stats h-25 w-full flex items-center justify-between border rounded-lg py-4">
              <dl className="px-4 space-y-1">
                <dt className="text-content-1">TVL</dt>
                <dd className="text-8 font-medium line-height-none">
                  <NumberView number={1000000} />
                </dd>
              </dl>
              <div className="flex items-center">
                <DivideVertical className="h-15" />
                <dl className="px-4 space-y-1">
                  <dt className="text-content-1">24H Volume</dt>
                  <dd className="text-8 font-medium line-height-none">
                    <NumberView before="$" number={584512} />
                  </dd>
                </dl>
              </div>
              <div className="flex items-center">
                <DivideVertical className="h-15" />
                <dl className="px-4 space-y-1">
                  <dt className="flex items-center text-content-1">
                    Price Range <ProjectSwitchIcon className="ml-2 size-3.5 cursor-pointer" />
                  </dt>
                  <dd className="flex items-end">
                    <NumberView number={0.80322} className="text-8 font-medium line-height-none" />
                    <span className="text-sm">per B token</span>
                    <span className="mx-1 text-sm text-content-3">Min Price</span>
                    <span className="mx-3">-</span>
                    <NumberView number={0.99322} className="text-8 font-medium line-height-none" />
                    <span className="text-sm">per B token</span>
                    <span className="mx-1 text-sm text-content-3">Max Price</span>
                  </dd>
                </dl>
              </div>
            </div>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@burve-front/react-ui';

import { useTranslation } from 'react-i18next';
import { RouterFlow } from './router-flow';

export const RoutesView = () => {
  const { t } = useTranslation();
  return (
    <Dialog>
      <DialogTrigger className="text-primary cursor-pointer">{t('burve-api')}</DialogTrigger>
      <DialogContent className="w-[460px] h-[190px]">
        <DialogTitle className="w-full text-center lg:font-bold">{t('order-routing')}</DialogTitle>
        <DialogDescription />
        <RouterFlow />
      </DialogContent>
    </Dialog>
  );
};

/**
 * Converts a scientific notation number to a string, preserving its original precision.
 * @param {any} value - The scientific notation number to convert.
 * @returns {string} The number as a string.
 */
export const fmtSafeToString = (value: any): `${number}` | string => {
  const num = Number(value);
  if (Number.isNaN(num) || num === 0 || Math.abs(num) <= Number.MIN_VALUE || Math.abs(num) >= Number.MAX_VALUE) return '0';
  const str = value.toString();
  const index = str.indexOf('e-');
  if (index !== -1) {
    const precision = Number.parseInt(str.slice(index + 2), 10);
    return `0.${'0'.repeat(precision - 1)}${str.slice(0, index).replace('.', '')}`;
  }
  const match = str.match(/^-?\d+(?:\.\d+)?/);
  if (match) {
    const numberString = match[0];
    const trailingZeros = numberString.match(/\.(\d*?)0*$/);
    const decimalPlaces = trailingZeros ? trailingZeros[1].length : 0;
    return Number(value).toFixed(decimalPlaces);
  }
  return str;
};

/**
 * @param {string} value
 * @returns
 */
export const fmtFirstToUpper = (value?: string) => {
  return value ? value.replace(/^\S/, (s) => s.toUpperCase()) : '';
};

/**
 * @param {string | Address} address
 * @param {number} [before=6]
 * @param {number} [after=6]
 * @returns
 */
export const fmtAddress = (address?: string, before = 6, after = 6) => {
  if (!address) return '';
  const addr = address.trim().toLowerCase();
  return `${addr.substring(0, before)}${addr ? '...' : ''}${addr.substring(addr.length - after)}`;
};

/**
 * using a currency library here in case we want to add more in future

 * @param {any} value - number
 * @param {number} [decimal=3] -
 * @param {Function} [roundingFunction=Math.round] -
 * @param {boolean} [average=true] -
 * @returns {[string,string,string]}
 */
export const fmtAmount = (value: any, decimal = 3, roundingFunction = Math.floor, truncate = false) => {
  let num = Number(value);
  let before = '';
  let unit = '';
  const decimalPlaces = decimal < 0 ? 0 : decimal;
  if (Number.isNaN(num) || num === Number.POSITIVE_INFINITY) return ['-', '-', unit];
  if (num === 0) return ['0', '0', unit];
  if (num < 0) before += '-';
  num = Math.abs(num);
  if (num >= Number.MAX_VALUE) {
    before += '>';
    num = Number.MAX_VALUE;
  }
  if (num < Number.MIN_VALUE) {
    before += '<';
    num = Number.MIN_VALUE;
  }
  if (num >= 10 ** 18) {
    num = num / 10 ** 18;
    unit = 'Qa';
  } else if (num >= 10 ** 15) {
    num = num / 10 ** 15;
    unit = 'Q';
  } else if (num >= 10 ** 12) {
    num = num / 10 ** 12;
    unit = 'T';
  } else if (num >= 10 ** 9) {
    num = num / 10 ** 9;
    unit = 'B';
  } else if (num >= 10 ** 6) {
    num = num / 10 ** 6;
    unit = 'M';
  } else if (num >= 10 ** 4) {
    num = num / 10 ** 3;
    unit = 'K';
  }
  let str = fmtSafeToString(num);
  if (str === '-') return ['-', '-', unit];
  const minCropNum = 10 ** -decimalPlaces;
  let [integerPart = '', decimalPart = ''] = str.split('.');
  let zeroStr = '';
  if (decimalPart && num < minCropNum) {
    const zeroLen = decimalPart.replace(/[1-9][0-9]*$/g, '').length;
    decimalPart = decimalPart.replace(/00*/, '').substring(0, decimalPlaces).replace(/00/, '');
    zeroStr = zeroLen && zeroLen > 0 ? `0{${zeroLen}}` : '';
  } else {
    const noZeroIndex = Array.from(decimalPart).findIndex((a) => Number(a) > 0);
    const noZeroLen =
      noZeroIndex === -1 || (noZeroIndex === decimalPart.length - 1 && Number(decimalPart.replace(/0/g, '')) < 5)
        ? 0
        : noZeroIndex;
    const noZeroDecimal = noZeroLen + decimalPlaces;
    const maxCropNum = 10 ** noZeroDecimal;
    const decimalNum = Number(`${integerPart}.${decimalPart.substring(0, noZeroDecimal + 1)}`);
    const roundedNum = roundingFunction(decimalNum * maxCropNum) / maxCropNum;
    str = fmtSafeToString(roundedNum.toFixed(truncate ? decimalPlaces : noZeroDecimal));
    [integerPart = '', decimalPart = ''] = str.split('.');
  }
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const viewNum = `${before}${integerPart}${decimalPart ? '.' : ''}${zeroStr}${decimalPart}`;
  return [`${viewNum}${unit}`, viewNum, unit];
};

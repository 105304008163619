import type { Chain } from 'viem';
import { base, bsc, sepolia } from 'viem/chains';
import { coinbaseWallet, injected } from 'wagmi/connectors';
import { APP_NAME, TEST } from './app';

// support chains
export const EVM_CHAINS: [Chain, ...Chain[]] = TEST ? [sepolia, bsc] : [base, bsc];

/// default chain
export const EVM_DEFAULT_CHAIN = EVM_CHAINS[0];

// support wallet
export const EVM_CONNECTORS = [
  injected({
    target: {
      id: 'metaMask',
      name: 'MetaMask',
      provider() {
        return typeof window?.ethereum !== 'undefined' && window?.ethereum?.isMetaMask ? window.ethereum : undefined;
      },
    },
  }),
  coinbaseWallet({
    appName: APP_NAME,
    headlessMode: false,
    version: '4',
  }),
  // walletConnect({
  //   projectId: '', // TODO walletConnect projectId
  //   qrModalOptions: {
  //     themeVariables: {
  //       // @ts-ignore
  //       '--wcm-z-index': 1000,
  //     },
  //   },
  // }),
  injected(),
];

// max approve value
export const MAX_APPROVE_AMOUNT = BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff');

import { cn } from '@/utils';

type DivideProps = {
  className?: string;
};
export const DivideHorizontal = ({ className }: DivideProps) => {
  return (
    <div className={cn('h-px w-full from-[#49417c] via-[#dcd6ff] to-[#49417C] bg-gradient-to-l flex-shrink-0', className)} />
  );
};

export const DivideVertical = ({ className }: DivideProps) => {
  return (
    <div
      className={cn(
        'h-full w-px rounded-lg bg-[linear-gradient(180deg,rgba(177,117,255,0)_0%,#FFFFFF_50%,rgba(154,77,255,0)_98%)] flex-shrink-0',
        className,
      )}
    />
  );
};

import { useMemo } from 'react';
import { EdgeLabelRenderer, SmoothStepEdge, type SmoothStepEdgeProps } from 'reactflow';

export type EdgeData = {
  topTip: string;
  bottomTip: string;
};
export type CustomEdgeProps = SmoothStepEdgeProps<EdgeData>;
export const CustomEdge = ({ sourceX, sourceY, targetX, targetY, ...props }: CustomEdgeProps) => {
  const isTwo = useMemo(() => props.source === '2', [props.source]);
  return (
    <>
      <SmoothStepEdge {...props} sourceX={sourceX} sourceY={sourceY} targetX={targetX} targetY={targetY} />
      <EdgeLabelRenderer>
        <button
          type="button"
          className="absolute text-sm text-primary"
          style={{
            transform: `translate(-50%, -50%) translate(${sourceX - (isTwo ? 0 : -65) + sourceX / 2}px,${sourceY + targetY - 20}px)`,
          }}>
          {props.data?.topTip}
        </button>
      </EdgeLabelRenderer>
      <EdgeLabelRenderer>
        <div
          className="absolute text-center text-sm text-second"
          style={{
            transform: `translate(-50%, -50%) translate(${sourceX - (isTwo ? 0 : -65) + sourceX / 2}px,${sourceY + targetY + 10}px)`,
          }}>
          {props.data?.bottomTip}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

import type { Metric, ReportOpts } from 'web-vitals';

export const consoleVersion = (version: string, name: string) => {
  console.log(`%c ${name} version ${version}`, 'color: #fff; background-color: #000; padding: 5px;');
};

export const fps = () => {
  let prevTime = Date.now();
  let frames = 0;
  const container = document.createElement('div');
  container.id = 'fps';
  container.setAttribute('style', 'color:green;position:fixed;left:5px;top:0px;z-index:999;background-color:rgba(0,0,0,0.6)');
  document.body.appendChild(container);
  const element = document.getElementById('fps');
  const update = () => {
    const time = Date.now();
    frames++;
    if (time > prevTime + 1000) {
      const fps = Math.round((frames * 1e3) / (time - prevTime));
      prevTime = time;
      frames = 0;
      if (element) element.innerText = `fps: ${fps}`;
      // console.info('fps: ', fps);
    }
    window.requestAnimationFrame(update);
  };
  window.requestAnimationFrame(update);
};

export const reportWebVitals = async (onReport: (metric: Metric) => void, opts?: ReportOpts) => {
  if (onReport && onReport instanceof Function) {
    const { onCLS, onINP, onFCP, onLCP, onTTFB } = await import('web-vitals');
    onCLS(onReport, opts);
    onINP(onReport, opts);
    onFCP(onReport, opts);
    onLCP(onReport, opts);
    onTTFB(onReport, opts);
  }
};

export const erudaConsole = async () => {
  const eruda = await import('eruda').then((e) => e.default);
  //@ts-ignore
  const erudaMonitor = await import('eruda-monitor').then((e) => e.default); // fps and memory
  //@ts-ignore
  const erudaTiming = await import('eruda-timing').then((e) => e.default); // performance and resource timing
  //@ts-ignore
  const erudaFeatures = await import('eruda-features').then((e) => e.default); // browser feature detections
  eruda.init();
  eruda.add(erudaTiming);
  eruda.add(erudaMonitor);
  eruda.add(erudaFeatures);
};

import { pools } from '@/apis/mocks';
import { SearchPool } from '@/components/search-pool';
import { Pool } from '@/types/pool';
import {
  Button,
  NumberView,
  PairAvatar,
  ProjectArrowRightIcon,
  ProjectDownIcon,
  ProjectManageIcon,
  VirtualTable,
  cn,
} from '@burve-front/react-ui';
import {
  ColumnDef,
  Row,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useLocation } from 'wouter';

const columns: ColumnDef<Pool>[] = [
  {
    id: 'token',
    enableSorting: false,
    header: () => <span>Tokens</span>,
    accessorKey: 'address',
    cell: ({ row }) => {
      return (
        <div className="flex items-center space-x-1">
          <PairAvatar one={row.original.oneLogo} two={row.original.twoLogo} />
          <span>{row.original.name}</span>
          <span>-</span>
          <span>{row.original.symbol}</span>
        </div>
      );
    },
  },
  {
    id: 'tvl',
    header: () => <span>TVL</span>,
    accessorKey: 'tvl',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'volume',
    header: () => <span>24 Volume</span>,
    accessorKey: 'volume',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'fees',
    header: () => <span>24 Fees</span>,
    accessorKey: 'fees',
    cell: (info) => <NumberView before="$" number={info.getValue()} />,
  },
  {
    id: 'expand',
    enableSorting: false,
    size: 200,
    cell: ({ row }) => (
      <>
        {row.getCanExpand() ? (
          <div
            className="size-full flex cursor-pointer items-center justify-end text-end hover:text-primary"
            onClick={row.getToggleExpandedHandler()}>
            <ProjectDownIcon className={cn('h-2 w-2.5 fill-current', row.getIsExpanded() ? 'rotate-180' : '')} />
          </div>
        ) : (
          <div className="w-full flex cursor-pointer items-center justify-end text-end text-content-1">
            <ProjectArrowRightIcon className="h-2 w-2.5 fill-current" />
          </div>
        )}
      </>
    ),
  },
];

const ThePoolsPage = () => {
  const table = useReactTable({
    data: pools,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getSubRows: (row) => row.pools,
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: !import.meta.env.PROD,
  });
  const [, navigate] = useLocation();
  const handleRowClick = (row: Row<Pool>) => {
    navigate(`/pool/${row.original.address}`);
  };
  return (
    <div className="mx-auto max-w-[1100px] w-full flex-1 pt-6">
      <div className="h-25 flex items-center justify-between space-x-5">
        <dl className="border-gradient-card size-full rounded-lg bg-[#310E38]/20 p-4">
          <dt className="text-content-1">BLP TVL</dt>
          <dd className="text-8 font-medium">$82,231,073.94</dd>
        </dl>
        <dl className="border-gradient-card size-full rounded-lg bg-[#310E38]/20 p-4">
          <dt className="text-content-1">Fees</dt>
          <dd className="text-8 font-medium">$37,317.56</dd>
        </dl>
        <dl className="border-gradient-card size-full rounded-lg bg-[#310E38]/20 p-4">
          <dt className="flex items-center justify-between">
            <span className="text-content-1"> Your Positions</span>
            <Button variant="primary" className="text-xs">
              <ProjectManageIcon className="mr-2 fill-current" />
              Manage Position
            </Button>
          </dt>
          <dd className="text-8 font-medium">$1,296,927.06</dd>
        </dl>
      </div>
      <div className="mt-10 flex items-center justify-between">
        <div className="text-6 font-medium">Liquidity Pools</div>
        <SearchPool />
      </div>
      <VirtualTable
        className="mt-4 bg-[linear-gradient(180deg,rgba(121,125,149,0)_0%,rgba(121,125,149,0.2)_100%)] bg-transparent"
        headClassName="bg-[#797D95]/15"
        trClassName="pr-5"
        table={table}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

export default ThePoolsPage;

import { ProjectArrowRightIcon, ProjectXIcon } from '@burve-front/react-ui/src/assets';
import { cn } from '@burve-front/react-ui/src/utils';
import { SplideSlide } from 'react-splide-ts';
import { useAdStore } from './ad-store';

export type AdItem = {
  id: number;
  img?: string;
  text?: string;
  href?: string;
};
type AdProps = {
  item: AdItem;
};
export const AdItem = ({ item }: AdProps) => {
  const setIsCloseAd = useAdStore((state) => state.setIsCloseAd);
  return (
    <SplideSlide className="relative m-0 w-full flex items-center justify-center p-0">
      {item.img && <img className="h-9 w-full object-cover lg:object-contain" src={item.img} aria-label={item.href} />}
      <div
        className={cn(
          'absolute mx-auto size-full max-w-[1500px] flex items-center justify-center container',
          item.text && 'py-2 text-center text-sm font-normal',
        )}>
        {item.text && item.text}
        <a
          href={item.href}
          aria-label={item.href}
          target="_blank"
          rel="noreferrer"
          className={cn('cursor-pointer', item.text ? 'ml-2' : 'size-full flex-1')}>
          {item.text && <ProjectArrowRightIcon className="h-2 w-3 transition-all hover:translate-x-1" />}
        </a>
        <ProjectXIcon
          className="absolute right-6 size-[9px] cursor-pointer transition-all md:right-0 hover:rotate-90"
          onClick={() => setIsCloseAd(true)}
        />
      </div>
    </SplideSlide>
  );
};

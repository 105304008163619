import { useSwapStore } from '@/store/swap-store';
import { Button, Input, ProjectArrowLeftIcon, cn } from '@burve-front/react-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

const slippageOptions = [0.5, 1, 3, 5, 10];

export const SettingView = () => {
  const { t } = useTranslation();
  const setViewMode = useSwapStore((state) => state.setViewMode);
  const slippage = useSwapStore((state) => state.slippage);
  const setSlippage = useSwapStore((state) => state.setSlippage);
  const isCustom = useMemo(() => !slippageOptions.some((item) => item === slippage), [slippage]);
  const [inputValue, setInputValue] = useState<string>('');
  const inputError = useMemo(
    () => Number.isNaN(Number(inputValue)) || Number(inputValue) > 100 || Number(inputValue) < 0,
    [inputValue],
  );
  useEffect(() => {
    if (inputError) setSlippage(slippage);
    else if (inputValue !== '') setSlippage(Number(inputValue));
  }, [inputError, inputValue, slippage, setSlippage]);
  return (
    <div className="border-gradient-primary rounded-lg p-4 text-sm min-h-[277px] space-y-3">
      <div
        onClick={() => setViewMode('input')}
        className="text-content-1 flex items-center space-x-1 transition-transform hover:-translate-x-1 cursor-pointer">
        <ProjectArrowLeftIcon className="w-[10px] h-2 fill-current" />
        <h3>{t('setting')}</h3>
      </div>
      <div className="space-y-2">
        <h3>{t('slippage-tolerance')}</h3>
        <div className="pt-2 grid grid-cols-3 gap-3">
          {slippageOptions.map((item) => (
            <Button
              variant="outline"
              key={`slippage-${item}`}
              onClick={() => {
                setSlippage(item);
              }}
              className={cn(
                'bg-[#16162E]/60 border-transparent hover:border-[#acb0c8]/50 text-[#acb0c8]',
                slippage === item &&
                  'bg-[linear-gradient(0deg,rgba(154,77,255,0.8)_0%,rgba(154,77,255,0)_100%)] border border-[#AB79E8] text-white',
              )}>
              {item}%
            </Button>
          ))}
          <Button
            className={cn(
              'bg-[#16162E]/60 border-transparent hover:border-[#acb0c8]/50 text-[#acb0c8]',
              isCustom &&
                'bg-[linear-gradient(0deg,rgba(154,77,255,0.8)_0%,rgba(154,77,255,0)_100%)] border border-[#AB79E8] text-white',
            )}>
            {t('custom')}
          </Button>
        </div>
        <div className="relative h-9.5">
          <Input
            className={cn('px-2 h-full', inputError && 'border-error')}
            value={inputValue}
            onChange={({ currentTarget }) => setInputValue(currentTarget.value)}
          />
          <div className="absolute right-0 top-0 flex items-center h-full px-2 text-content-1">
            <div>| %</div>
          </div>
        </div>
      </div>
    </div>
  );
};

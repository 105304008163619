import type {
  CustomSeriesPricePlotValues,
  ICustomSeriesPaneView,
  PaneRendererCustomData,
  Time,
  WhitespaceData,
} from 'lightweight-charts';
import type { CustomHistogramData } from './data';
import { type CustomHistogramSeriesOptions, customHistogramDefaultOptions } from './options';
import { CustomHistogramSeriesRenderer } from './renderer';

export class CustomHistogramSeries<TData extends CustomHistogramData>
  implements ICustomSeriesPaneView<Time, TData, CustomHistogramSeriesOptions>
{
  _renderer: CustomHistogramSeriesRenderer<TData>;

  constructor() {
    this._renderer = new CustomHistogramSeriesRenderer();
  }

  priceValueBuilder(plotRow: TData): CustomSeriesPricePlotValues {
    return [0, plotRow.value];
  }

  isWhitespace(data: TData | WhitespaceData): data is WhitespaceData {
    return !(data as Partial<TData>).value;
  }

  renderer(): CustomHistogramSeriesRenderer<TData> {
    return this._renderer;
  }

  update(data: PaneRendererCustomData<Time, TData>, options: CustomHistogramSeriesOptions): void {
    this._renderer.update(data, options);
  }

  defaultOptions() {
    return customHistogramDefaultOptions;
  }
}

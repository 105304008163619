import { TEST } from '@/confs/app';
import useChain from '@/hooks/use-chain';
import useEvmChain from '@/hooks/use-evm-chain';
import { useModalStore } from '@/store/modal-store';
import { getOS, openHref } from '@burve-front/core';
import {
  AddressView,
  Badge,
  Button,
  CONNECTORS_META,
  CommonPowerIcon,
  ConnectorsAvatar,
  SpanLoading,
  UserAvatar,
} from '@burve-front/react-ui';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Connector, useAccount, useConnect, useDisconnect } from 'wagmi';

export const EvmWallets = () => {
  const { t } = useTranslation();
  const setIsConnectModalOpen = useModalStore((state) => state.setIsConnectModalOpen);
  const isConnectModalOpen = useModalStore((state) => state.isConnectModalOpen);
  const { switchChain, chain } = useChain();
  const { chain: evmChain } = useEvmChain();
  const [pending, setPending] = useState<string>();
  const { address, isConnected, connector } = useAccount();
  const { disconnect } = useDisconnect();
  const { connectAsync, reset, connectors, error, isPending } = useConnect();
  const [isReadyList, setIsReadyList] = useState<boolean[]>([]);
  const { isMobile } = useMemo(() => getOS() ?? { isMobile: false }, []);

  const handleConnect = async (connector: Connector) => {
    setPending(connector.id);
    const { getDownLink, getDeepLink, getDisplayUri } = CONNECTORS_META[connector.id];
    const linkFn = getDisplayUri ?? (isMobile ? (getDeepLink ?? getDownLink) : getDownLink);
    try {
      if (chain?.custom?.isSolana) await switchChain(evmChain.name);
      const meta = CONNECTORS_META[connector.id];
      const provider = await connector.getProvider().catch(() => false);
      if (linkFn && meta.isWalletConnect) {
        //@ts-ignore
        provider?.once('display_uri', (uri) => openHref(linkFn(chain, uri)));
      }
      if (!provider && linkFn) {
        openHref(linkFn(chain));
      } else {
        await connectAsync({ connector, chainId: chain.id });
      }
      setIsConnectModalOpen(false);
    } catch (error) {
      if (TEST) console.error(error);
    }
    setPending(undefined);
  };

  const queryProviders = useCallback(async () => {
    const res = await Promise.all(
      connectors.map((item) =>
        item
          .getProvider()
          .then((res) => res !== undefined)
          .catch(() => false),
      ),
    );
    setIsReadyList(res);
  }, [connectors]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isConnectModalOpen) queryProviders();
  }, [connectors, isConnectModalOpen, queryProviders]);

  useEffect(() => {
    if (!isConnectModalOpen) reset();
  }, [isConnectModalOpen, reset]);

  if (isConnected && connector) {
    return (
      <div className="flex flex-col space-y-2">
        <div className="h-10 flex items-center justify-center rounded-md text-sm space-x-1">
          <UserAvatar address={address} className="size-5" />
          <AddressView address={address} showTip={false} showCopy={false} />
          <span className="">({connector.name})</span>
          <Button variant="outline" size="icon" className="size-8 cursor-pointer" onClick={() => disconnect()}>
            <CommonPowerIcon className="size-3" />
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-3">
      {connectors.map((item, index) => (
        <Button
          key={item.id}
          variant="secondary"
          size="lg"
          disabled={isPending}
          onClick={() => handleConnect(item)}
          className="w-full justify-start px-4 space-x-2">
          <ConnectorsAvatar connectorName={item.id} />
          <span className="text-sm font-bold">{item.name}</span>
          <span className="inline-flex items-center space-x-2">
            {isReadyList?.[index] && (
              <Badge variant="outline" className="border-foreground px-1 py-0 text-[10px] opacity-60">
                {t('ready')}
              </Badge>
            )}
          </span>
          {isPending && item.id === pending && <SpanLoading />}
        </Button>
      ))}
      {error && <p className="text-center text-error">{error.message.split('.')[0]}</p>}
    </div>
  );
};

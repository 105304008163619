import { cn } from '@/utils';
import type { ReactNode } from 'react';
import { ProjectEmptyIcon } from '../../assets';

type EmptyProps = {
  className?: string;
  children?: ReactNode;
};
export const Empty = ({ className, children }: EmptyProps) => {
  return (
    <div className={cn('flex flex-col items-center justify-center pt-5 pb-20', className)}>
      <ProjectEmptyIcon className="size-25" />
      <div className="text-content-3 text-sm">{children}</div>
    </div>
  );
};

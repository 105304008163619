import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  ProjectDownIcon,
  cn,
} from '@burve-front/react-ui';
import { useState } from 'react';

type Item = {
  value: number;
  label: string;
};

const days: Item[] = [
  {
    value: 1 * 24 * 60 * 60 * 1000,
    label: '1D',
  },
  {
    value: 30 * 24 * 60 * 60 * 1000,
    label: '1M',
  },
  {
    value: 365 * 24 * 60 * 60 * 1000,
    label: '1Y',
  },
];

type SortDayProps = {
  onChange?: (item: Item) => void;
};

export const SortDay = ({ onChange }: SortDayProps) => {
  const [select, setSelect] = useState(days[0]);
  const handleSelect = (item: Item) => {
    setSelect(item);
    onChange?.(item);
  };
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="px-[10px] text-content-1">
          {select.label}
          <ProjectDownIcon className="ml-[6px] size-2" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" className="min-w-12">
        {days.map((item) => (
          <DropdownMenuItem
            key={`sort-day-item-${item.label}`}
            onClick={() => handleSelect(item)}
            className={cn('h-10 cursor-pointer font-normal', select.value === item.value && 'text-content')}>
            {item.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

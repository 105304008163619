import { type ChartOptions, ColorType, type DeepPartial, LineStyle, type Time, createChart } from 'lightweight-charts';
import { useEffect, useRef } from 'react';
import { StackedBarsSeries } from './stacked-bars-series';
import type { StackedBarsData } from './stacked-bars-series/data';
import type { StackedBarsSeriesOptions } from './stacked-bars-series/options';

type VolumeChartProps = {
  series: {
    data: StackedBarsData[];
    options?: StackedBarsSeriesOptions;
  }[];
  className?: string;
  options?: Omit<DeepPartial<ChartOptions>, 'width' | 'height'>;
  visibleNum?: number;
};
const borderColor = '#49417c';
const textColor = '#9ca1b6';
const backgroundColor = 'transparent';
const stackedBarsView = new StackedBarsSeries();
export const VolumeChart = ({ series, className, options, visibleNum }: VolumeChartProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!chartContainerRef.current) return;
    const handleResize = () => {
      if (!chartContainerRef.current) return;
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };
    const chart = createChart(chartContainerRef.current, {
      ...options,
      layout: {
        textColor,
        attributionLogo: false,
        ...options?.layout,
        background: { type: ColorType.Solid, color: backgroundColor, ...options?.layout?.background },
      },
      crosshair: {
        ...options?.crosshair,
        vertLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.vertLine },
        horzLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.horzLine },
      },
      grid: {
        vertLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.vertLines },
        horzLines: { visible: true, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.horzLines },
      },
      leftPriceScale: { borderColor, ...options?.leftPriceScale, minimumWidth: 52 },
      rightPriceScale: { borderColor, ...options?.rightPriceScale, minimumWidth: 52 },
      timeScale: { borderColor, ...options?.timeScale },
      height: chartContainerRef.current.clientHeight,
      width: chartContainerRef.current.clientWidth,
    });
    for (let i = 0; i < series.length; i++) {
      const serie = series[i];
      const stackedBarsSeries = chart.addCustomSeries(stackedBarsView, {
        color: '#ff63fa',
        lastValueVisible: false,
        priceLineVisible: false,
      });
      stackedBarsSeries.setData(serie.data);
    }
    const allData = series.flatMap((item) => item.data);
    let to: Time | undefined;
    let from: Time | undefined;
    if (visibleNum) {
      to = allData[allData.length - 1]?.time;
      from = allData[allData.length - 2 - visibleNum]?.time;
    }
    if (from && to) chart.timeScale().setVisibleRange({ from, to });
    else chart.timeScale().fitContent();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [series, options, visibleNum]);

  return <div ref={chartContainerRef} className={className} />;
};

import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import { type Chain, defineChain } from 'viem';

type getSolanaChainProps = {
  id: number;
  network: WalletAdapterNetwork;
  name: string;
  testnet?: boolean;
  http?: string[];
};
export type SolanaChain = Chain & {
  custom: {
    isSolana: true;
    network: WalletAdapterNetwork;
  };
};
export const getSolanaChain = ({
  id,
  name,
  network,
  testnet,
  http,
}: getSolanaChainProps): Chain & {
  custom: {
    isSolana: true;
    network: WalletAdapterNetwork;
  };
} => {
  let params = '';
  const defaultRpc = clusterApiUrl(network);
  switch (network) {
    case WalletAdapterNetwork.Devnet:
      params = '?cluster=devnet';
      break;
    case WalletAdapterNetwork.Testnet:
      params = '?cluster=testnet';
      break;
  }
  return defineChain({
    id,
    name,
    nativeCurrency: { name: 'SOL', symbol: 'SOL', decimals: 9 },
    rpcUrls: { default: { http: http ? [...http, defaultRpc] : [defaultRpc] } },
    blockExplorers: {
      default: {
        name: 'Solana Explorer (Beta)',
        url: `https://explorer.solana.com${params}`,
      },
    },
    testnet,
    custom: {
      isSolana: true,
      network,
    },
  });
};

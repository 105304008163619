import { TEST } from '@/confs/app';
import useChain from '@/hooks/use-chain';
import useConnectInfo from '@/hooks/use-connect-info';
import { useModalStore } from '@/store/modal-store';
import { customToast } from '@burve-front/react-ui';
import {
  Button,
  ChainAvatar,
  CommonPowerIcon,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  SpanLoading,
} from '@burve-front/react-ui';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDisconnect } from 'wagmi';

export const SwitchChainDialog = () => {
  const { t } = useTranslation();
  const isChainModalOpen = useModalStore((state) => state.isChainModalOpen);
  const setIsChainModalOpen = useModalStore((state) => state.setIsChainModalOpen);
  const [error, setError] = useState<string>();
  const { isConnected, chain } = useConnectInfo();
  const { disconnect } = useDisconnect(); // TODO solana
  const { supplyChains, switchChain, isPending, pendingChain } = useChain();

  const unsupported = useMemo(() => !supplyChains.some((item) => item.id === chain?.id), [chain?.id, supplyChains]);

  const switchChainHandler = async (chainName: string) => {
    try {
      await switchChain(chainName);
    } catch (error: any) {
      setError(error.shortMessage ?? error.message ?? error);
      if (TEST) console.error(error);
    }
  };

  useEffect(() => {
    if (isConnected) setIsChainModalOpen(unsupported);
    else setIsChainModalOpen(false);
  }, [isConnected, setIsChainModalOpen, unsupported]);

  return (
    <Dialog
      open={isChainModalOpen}
      onOpenChange={(open) => {
        if (unsupported) {
          customToast.remove();
          customToast.error(t('change-network-tip'));
        } else {
          setIsChainModalOpen(open);
        }
      }}>
      <DialogContent className="max-w-xs">
        <DialogTitle className="text-center">{t('switch-network')}</DialogTitle>
        <DialogDescription asChild>
          <div className="flex flex-col p-2 pb-0 space-y-4">
            {supplyChains.map((item) => (
              <Button
                variant="secondary"
                key={item.id}
                disabled={isPending}
                onClick={() => switchChainHandler(item.name)}
                className="w-full justify-start space-x-2">
                <ChainAvatar chainName={item.name} />
                <dl className="text-left leading-none">
                  <dt>{item.name}</dt>
                  {item.testnet && <dd className="text-[10px]">{t('testnet')}</dd>}
                </dl>
                {isPending && item.name === pendingChain?.name && <SpanLoading />}
              </Button>
            ))}
            {error && <p className="text-center text-error">{error}</p>}
          </div>
        </DialogDescription>
        <DialogFooter className="p-2">
          <Button variant="outline" className="w-full cursor-pointer space-x-1" onClick={() => disconnect()}>
            <CommonPowerIcon className="size-3" />
            <span>{t('disconnect')}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

import { APP_THEME, TEST } from '@/confs/app';
import { useEffect, useRef } from 'react';
import useChain from './use-chain';
import useTheme from './use-theme';

export const useInitApp = () => {
  const ref = useRef(true);
  const { switchTheme } = useTheme();
  const { searchChainName, switchChain } = useChain();
  useEffect(() => {
    if (ref.current) {
      if (TEST) console.info('init App');
      switchTheme(APP_THEME);
      if (searchChainName) switchChain(searchChainName);
      ref.current = false;
    }
  }, [searchChainName, switchChain, switchTheme]);
};

export default useInitApp;

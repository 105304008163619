import {
  type AreaSeriesPartialOptions,
  type ChartOptions,
  ColorType,
  CrosshairMode,
  type DeepPartial,
  LineStyle,
  type SeriesDataItemTypeMap,
  createChart,
} from 'lightweight-charts';
import { useEffect, useRef } from 'react';

type AreaChartProps = {
  series: {
    data: SeriesDataItemTypeMap['Area'][];
    options?: AreaSeriesPartialOptions;
  }[];
  className?: string;
  options?: Omit<DeepPartial<ChartOptions>, 'width' | 'height'>;
};
const borderColor = '#49417c';
const textColor = '#9ca1b6';
const backgroundColor = 'transparent';
export const AreaChart = ({ series, className, options }: AreaChartProps) => {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!chartContainerRef.current) return;
    const handleResize = () => {
      if (!chartContainerRef.current) return;
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };
    const chart = createChart(chartContainerRef.current, {
      ...options,
      layout: {
        textColor,
        attributionLogo: false,
        ...options?.layout,
        background: { type: ColorType.Solid, color: backgroundColor, ...options?.layout?.background },
      },
      crosshair: {
        ...options?.crosshair,
        mode: CrosshairMode.Normal,
        vertLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.vertLine },
        horzLine: { color: '#acb0c8', labelBackgroundColor: '#acb0c8', ...options?.crosshair?.horzLine },
      },
      grid: {
        vertLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.vertLines },
        horzLines: { visible: false, style: LineStyle.Dashed, color: borderColor, ...options?.grid?.horzLines },
      },
      leftPriceScale: { borderColor, ...options?.leftPriceScale, minimumWidth: 52 },
      rightPriceScale: { borderColor, ...options?.rightPriceScale, minimumWidth: 52 },
      timeScale: { borderColor, ...options?.timeScale },
      height: chartContainerRef.current.clientHeight,
      width: chartContainerRef.current.clientWidth,
    });
    for (let i = 0; i < series.length; i++) {
      const serie = series[i];
      const newSeries = chart.addAreaSeries({
        lineColor: '#ff63fa',
        lineWidth: 2,
        topColor: 'rgba(255, 99, 250, 0.28)',
        bottomColor: 'rgba(255, 99, 250, 0)',
        lastValueVisible: false,
        priceLineVisible: false,
        ...serie.options,
      });
      newSeries.setData(serie.data);
    }
    chart.timeScale().fitContent();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      chart.remove();
    };
  }, [series, options]);

  return <div ref={chartContainerRef} className={className} />;
};

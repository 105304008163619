import { EChainType } from '@burve-front/core';
import { useWallet } from '@solana/wallet-adapter-react';
import { useMemo } from 'react';
import { useAccount } from 'wagmi';
import useChain from './use-chain';

const useConnectInfo = () => {
  const { chainType, chain: appChain } = useChain();
  const { isConnected: isEvmConnected, chain: evmChain } = useAccount();
  const { connected: isSolanaConnected } = useWallet();
  const isConnected = useMemo(
    () => (chainType === EChainType.Evm ? isEvmConnected : isSolanaConnected),
    [chainType, isSolanaConnected, isEvmConnected],
  );
  const chain = useMemo(() => (chainType === EChainType.Evm ? evmChain : appChain), [chainType, evmChain, appChain]);
  return {
    isConnected,
    isEvmConnected,
    isSolanaConnected,
    evmChain,
    chain,
  };
};

export default useConnectInfo;

import type { Icon } from '@/types';
import { cn } from '@/utils';

export type AvatarProps = {
  className?: string;
  avatar?: Icon;
  alt?: string;
};

export const Avatar = ({ alt, avatar, ...attrs }: AvatarProps) => {
  if (!avatar) return null;
  if (typeof avatar !== 'string') {
    const Icon = avatar as any as Icon;
    return <Icon {...attrs} className={cn('size-6', attrs.className)} />;
  }
  return <img {...attrs} alt={alt ?? 'avatar'} src={avatar} className={cn('size-6', attrs.className)} />;
};

import { links } from '@/confs/route';
import { cn } from '@burve-front/react-ui/src/utils';

import { NavItem, type NavItemProps } from './nav-item';

type NavProps = Omit<NavItemProps, 'item'>;

export const Nav = ({ className, type, showIcon = false, showLabel = true }: NavProps) => {
  return (
    <nav
      className={cn(
        type === 'col'
          ? 'flex-col flex overflow-auto size-full text-sm font-medium'
          : 'flex items-center justify-center space-x-2 lg:space-x-4 xl:space-x-10 text-sm font-medium',
        className,
      )}>
      {links
        .filter((item) => item.show !== false)
        .map((item, index) => (
          <NavItem key={`app-nav-${index}`} item={item} type={type} showIcon={showIcon} showLabel={showLabel} />
        ))}
    </nav>
  );
};

/**
 * get symstem theme
 * @returns {'light'|'dark'|undefined}
 */
export const getTheme = () => {
  if (typeof window === 'undefined') return;
  const isDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const isLight = window.matchMedia('(prefers-color-scheme: light)').matches;
  return isDark ? 'dark' : isLight ? 'light' : undefined;
};

export const setTheme = (theme: 'light' | 'dark' | string) => {
  if (typeof window === 'undefined') return;
  window.document.documentElement.classList.remove('light', 'dark');
  window.document.documentElement.classList.add(theme);
};

/**
 * scroll to id
 * @param id
 */
export const scrollToAnchor = (id: string) => {
  if (typeof window === 'undefined') return;
  if (id) document.getElementById(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
};

/**
 * get search params
 * @param {sting} name
 * @returns
 */
export const getSearchParam = (name: string) => {
  return typeof window !== 'undefined' ? new URLSearchParams(window.location.search).get(name) : undefined;
};

/**
 * set search patam
 * @param name
 * @param value
 */
export const setSearchParam = (name: string, value: string) => {
  if (typeof window === 'undefined') return;
  const url = new URL(window.location.href);
  url.searchParams.set(name, value);
  history.pushState({}, '', url);
};

/**

 * @returns  {isMobile,isPC,isAndroid,isIOS}
 */
export const getOS = () => {
  if (typeof window === 'undefined') return;
  const ua = navigator.userAgent;
  const isIOS = /iphone|ipad|ipod|ios/i.test(ua);
  const isAndroid = /android/i.test(ua);
  const isMobile = isIOS || isAndroid;
  const isPC = !isMobile;
  return {
    isMobile,
    isPC,
    isAndroid,
    isIOS,
  };
};

/**
 * get system language
 * @returns {string|undefined}
 */
export const getLanguage = (): string | undefined => {
  return typeof window !== 'undefined' ? window.navigator.language : undefined;
};

/**
 *
 * @param lang
 * @param direction
 */
export const setLocale = (lang: string, direction = 'ltr') => {
  if (typeof window === 'undefined') return;
  document.documentElement.setAttribute('lang', lang);
  window.document.documentElement.setAttribute('dir', direction);
};

/**
 *
 * @returns { lang?:string,dir?:string }
 */
export const getLocale = () => {
  if (typeof window === 'undefined') return;
  return {
    lang: document.documentElement.getAttribute('lang'),
    dir: window.document.documentElement.getAttribute('dir'),
  };
};

/**
 * open href in new tab
 * @param {string} href
 * @returns
 */
export const openHref = (href?: string) => {
  if (!href) return;
  window.open(href, '_blank');
  // const { isMobile } = OS();
  // if (isMobile) {
  //   const ifr = document.createElement('iframe');
  //   ifr.src = href;
  //   ifr.style.display = 'none';
  //   document.body.appendChild(ifr);
  // } else {
  //   window.open(href, '_blank');
  // }
};

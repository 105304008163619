import { Button, CommonWarningCircleIcon, StatusDialog } from '@burve-front/react-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmInfo } from './confirm-info';
export const Confirm = () => {
  const { t } = useTranslation();
  const [isPending, setIsPending] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const handleConfirmOrder = () => {
    setIsPending(true);
    setTimeout(() => {
      const isFailed = Math.random() >= 0.5;
      setIsPending(false);
      setIsFailed(isFailed);
      setIsConfirmed(!isFailed);
    }, 3000);
  };
  return (
    <div className="space-y-2">
      <Button variant="primary" size="lg" className="w-full mt-4" onClick={handleConfirmOrder}>
        {t('confirm-order')}
      </Button>
      <ConfirmInfo />
      <StatusDialog status="pending" isOpen={isPending} onOpenChange={setIsPending} title={t('pending-for-confirmation')}>
        <div className="space-y-4 mt-4 text-sm text-content-1">
          <div className="w-full flex justify-between items-center ">
            <span>Pay</span>
            <span>0.29105 DAISY(≈ $35.12)</span>
          </div>
          <div className="w-full flex justify-between items-center ">
            <span>Receive</span>
            <span>0.29105 USDT(≈ $35.120)</span>
          </div>
          <div className="space-y-1.5">
            <ConfirmInfo isOpen={true} />
            <div className="text-warn flex leading-tight">
              <CommonWarningCircleIcon className="size-3.5 mr-1 fill-warn flex-shrink-0" />
              There will be a large difference between your input and output values due to current liquidity.
            </div>
          </div>
          <p className="text-content-3 text-xs text-center">Please confirm your transaction in the wallet.</p>
        </div>
      </StatusDialog>
      <StatusDialog status="failed" isOpen={isFailed} onOpenChange={setIsFailed} title={t('transaction-failed')}>
        <div className="bg-[#16162E]/60 text-center rounded-md px-2 py-3 text-sm text-content-1 w-full truncate mt-4">
          User reject the request
        </div>
      </StatusDialog>
      <StatusDialog status="confirmed" isOpen={isConfirmed} onOpenChange={setIsConfirmed}>
        <div className="space-y-4 mt-4 text-sm text-content-1 text-center">
          <p>12.333 DASY received.</p>
          <Button variant="secondary">
            <a href="/" target="_blank" rel="noreferrer">
              View transaction
            </a>
          </Button>
        </div>
      </StatusDialog>
    </div>
  );
};

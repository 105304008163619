import { useCallback } from 'react';
import { useLocation } from 'wouter';

const useCheckActiveNav = () => {
  const [location] = useLocation();
  const checkActiveNav = useCallback(
    (nav: string) => {
      const pathname = location ?? window.location.pathname;
      if (nav === '/' && pathname === '/') return true;
      return pathname.includes(nav);
    },
    [location],
  );
  return { checkActiveNav };
};

export default useCheckActiveNav;

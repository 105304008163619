import { BannerItem } from './banner-item';
import './style.css';
import { banners } from '@/confs/app';

import { type Options, Splide } from 'react-splide-ts';

const options: Options = {
  perPage: 1,
  pagination: true,
  type: 'loop',
  autoplay: true,
  arrows: false,
  slideFocus: false,
  gap: 0,
  padding: 0,
};
export const Banner = () => {
  return (
    <Splide
      options={options}
      className="banner border-muted-foreground/20 h-30 w-full border-b pb-[5px] lg:h-[270px] !p-0 md:pb-[10px]">
      {banners.map((banner) => (
        <BannerItem key={`banner-${banner.id}`} item={banner} />
      ))}
    </Splide>
  );
};

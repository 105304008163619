import { ReactQueryProductionDevtools, persister, queryClient } from '@/utils/query-client';
import { wagmiConf } from '@/utils/wagmi-conf';
import { CustomToaster } from '@burve-front/react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { type FC, type ReactNode, Suspense } from 'react';
import { WagmiProvider } from 'wagmi';
import { SwitchWalletDialog } from './components/connect-wallet/switch-wallet-dialog';
import { SwitchChainDialog } from './components/switch-chain/switch-chain-dialog';
import { TEST } from './confs/app';
import { SOLANA_ADAPTERS } from './confs/solana';

import useCloudFlareAnalytics from './hooks/use-cloud-flare-analytics';
import useInitApp from './hooks/use-init-app';
import useNProgress from './hooks/use-nprogress';
import useRouterTitle from './hooks/use-route-title';
import useSolanaNetwork from './hooks/use-solana-network';

import { RecentTransactionDialog } from './components/connect-wallet/recent-transaction-dialog';
import { AppRouter } from './router';

const InitApp: FC = () => {
  useInitApp();
  useCloudFlareAnalytics();
  useNProgress();
  useRouterTitle();
  return null;
};

const Providers = ({ children }: { children: ReactNode }) => {
  const { endpoint } = useSolanaNetwork();
  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={SOLANA_ADAPTERS}>
        <WagmiProvider config={wagmiConf}>
          <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
            {TEST && ReactQueryProductionDevtools !== null && (
              <Suspense fallback={null}>
                <ReactQueryProductionDevtools initialIsOpen={false} buttonPosition="bottom-left" />
              </Suspense>
            )}
            {children}
            <SwitchWalletDialog />
            <SwitchChainDialog />
            <RecentTransactionDialog />
          </PersistQueryClientProvider>
        </WagmiProvider>
        <CustomToaster position="top-center" />
      </WalletProvider>
    </ConnectionProvider>
  );
};

const App = () => {
  return (
    <Providers>
      <InitApp />
      <AppRouter />
    </Providers>
  );
};

export default App;

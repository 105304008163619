import { CustomHistogramData, StackedBarsData } from '@burve-front/react-ui';
import type { CandlestickData, HistogramData, Time } from 'lightweight-charts';

export const chartData1 = [
  { time: '2018-10-19', value: 54.9 },
  { time: '2018-10-22', value: 54.98 },
  { time: '2018-10-23', value: 57.21 },
  { time: '2018-10-24', value: 57.42 },
  { time: '2018-10-25', value: 56.43 },
  { time: '2018-10-26', value: 55.51 },
  { time: '2018-10-29', value: 56.48 },
  { time: '2018-10-30', value: 58.18 },
  { time: '2018-10-31', value: 57.09 },
  { time: '2018-11-01', value: 56.05 },
  { time: '2018-11-02', value: 56.63 },
  { time: '2018-11-05', value: 57.21 },
  { time: '2018-11-06', value: 57.21 },
  { time: '2018-11-07', value: 57.65 },
  { time: '2018-11-08', value: 58.27 },
  { time: '2018-11-09', value: 58.46 },
  { time: '2018-11-12', value: 58.72 },
  { time: '2018-11-13', value: 58.66 },
  { time: '2018-11-14', value: 58.94 },
  { time: '2018-11-15', value: 59.08 },
  { time: '2018-11-16', value: 60.21 },
  { time: '2018-11-19', value: 60.62 },
  { time: '2018-11-20', value: 59.46 },
  { time: '2018-11-21', value: 59.16 },
  { time: '2018-11-23', value: 58.64 },
  { time: '2018-11-26', value: 59.17 },
  { time: '2018-11-27', value: 60.65 },
  { time: '2018-11-28', value: 60.06 },
  { time: '2018-11-29', value: 59.45 },
  { time: '2018-11-30', value: 60.3 },
  { time: '2018-12-03', value: 58.16 },
  { time: '2018-12-04', value: 58.09 },
  { time: '2018-12-06', value: 58.08 },
  { time: '2018-12-07', value: 57.68 },
  { time: '2018-12-10', value: 58.27 },
  { time: '2018-12-11', value: 58.85 },
  { time: '2018-12-12', value: 57.25 },
  { time: '2018-12-13', value: 57.09 },
  { time: '2018-12-14', value: 57.08 },
];
export const chartData2 = [
  { time: '2018-10-19', value: 24.9 },
  { time: '2018-10-22', value: 24.98 },
  { time: '2018-10-23', value: 37.21 },
  { time: '2018-10-24', value: 47.42 },
  { time: '2018-10-25', value: 36.43 },
  { time: '2018-10-26', value: 35.51 },
  { time: '2018-10-29', value: 46.48 },
  { time: '2018-10-30', value: 38.18 },
  { time: '2018-10-31', value: 47.09 },
  { time: '2018-11-01', value: 36.05 },
  { time: '2018-11-02', value: 46.63 },
  { time: '2018-11-05', value: 47.21 },
  { time: '2018-11-06', value: 27.21 },
  { time: '2018-11-07', value: 27.65 },
  { time: '2018-11-08', value: 48.27 },
  { time: '2018-11-09', value: 28.46 },
  { time: '2018-11-12', value: 48.72 },
  { time: '2018-11-13', value: 18.66 },
  { time: '2018-11-14', value: 28.94 },
  { time: '2018-11-15', value: 9.08 },
  { time: '2018-11-16', value: 30.21 },
  { time: '2018-11-19', value: 20.62 },
  { time: '2018-11-20', value: 39.46 },
  { time: '2018-11-21', value: 49.16 },
  { time: '2018-11-23', value: 28.64 },
  { time: '2018-11-26', value: 49.17 },
  { time: '2018-11-27', value: 30.65 },
  { time: '2018-11-28', value: 10.06 },
  { time: '2018-11-29', value: 29.45 },
  { time: '2018-11-30', value: 40.3 },
  { time: '2018-12-03', value: 38.16 },
  { time: '2018-12-04', value: 28.09 },
  { time: '2018-12-06', value: 38.08 },
  { time: '2018-12-07', value: 57.68 },
  { time: '2018-12-10', value: 42.27 },
  { time: '2018-12-11', value: 38.85 },
  { time: '2018-12-12', value: 27.25 },
  { time: '2018-12-13', value: 37.09 },
  { time: '2018-12-14', value: 37.08 },
];

export const volumeData: StackedBarsData[] = Array.from({ length: 60 }).map((_, i) => {
  const num = i + 1;
  const time = (1641558430 + num * 24 * 60 * 60) as Time;
  const values = [10.9 + Math.random(), 24.9 + Math.random()];
  return { time, values };
});

export const liquidityData: CustomHistogramData<number>[] = Array.from({ length: 60 }).map((_, i) => {
  const time = i * 10;
  const value = 24.9 + Math.random();
  let color = '';
  if (time <= 100) {
    color = 'rgba(26, 182, 255, 0.2)';
  } else if (time <= 200) {
    color = 'rgba(26, 182, 255, 0.8)';
  } else if (time <= 400) {
    color = 'rgba(255, 99, 250, 0.8)';
  } else {
    color = 'rgba(255, 99, 250, 0.2)';
  }
  return { time, value, color };
});

export const histogramData: HistogramData[] = [];
export const candlestickData: CandlestickData[] = [];

Array.from({ length: 30 }).map((_, i) => {
  const num = i + 1;
  const time = (1641558430 + num * 24 * 60 * 60) as Time;
  const value = Math.random() * Math.random() * 80;
  const isBuy = Math.random() >= 0.5;
  const buy = 'rgba(92, 229, 172, 1)';
  const sell = 'rgba(245, 83, 122, 1)';
  const color = isBuy ? buy : sell;
  const open = candlestickData[i - 1]?.close || Math.random() * 10;
  const close = open + Math.random() * (isBuy ? 1 : -1);
  const low = open - Math.random();
  const high = Math.max(open, close);
  histogramData.push({ time, color, value });
  candlestickData.push({ time, color, open, high, low, close });
});

import { type CustomSeriesOptions, customSeriesDefaultOptions } from 'lightweight-charts';

export interface StackedBarsSeriesOptions extends CustomSeriesOptions {
  colors: readonly string[];
  radius: number;
}

export const stackedBarsDefaultOptions: StackedBarsSeriesOptions = {
  ...customSeriesDefaultOptions,
  colors: ['#FFDD98', '#FF63FA'],
  radius: 4,
} as const;

import { InputView } from '@/components//input-view';
import { SettingView } from '@/components//setting-view';
import { Header } from '@/components/header';
import { useSwapStore } from '@/store/swap-store';
import { cn } from '@burve-front/react-ui';
import { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import type { Address } from 'viem';
import { i18n } from './i18n';
type SwapProps = {
  className?: string;
  pay?: Address;
  receive?: Address;
  options?: {
    lang?: 'en' | 'zh' | string;
  };
};

export const Swap = ({ className, options }: SwapProps) => {
  const { lang } = options || {
    lang: i18n.language,
  };
  const viewMode = useSwapStore((state) => state.viewMode);
  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);
  return (
    <I18nextProvider i18n={i18n}>
      <div className={cn('w-[435px] space-y-4', className)}>
        <Header />
        {viewMode === 'input' && <InputView />}
        {viewMode === 'setting' && <SettingView />}
      </div>
    </I18nextProvider>
  );
};

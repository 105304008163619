import useLocale from '@/hooks/use-locale';
import { Swap } from '@burve-front/react-swap';
const TheSwapPage = () => {
  const { locale } = useLocale();
  return (
    <div className="mx-auto max-w-[1100px] w-full flex-1 pt-8 md:pt-16">
      <Swap className="mx-auto" options={{ lang: locale }} />
    </div>
  );
};

export default TheSwapPage;

import { useMemo } from 'react';
import { CONNECTORS_META } from '../../constants';
import { Avatar, type AvatarProps } from './avatar';

type ConnectorsAvatar = AvatarProps & {
  connectorName: string;
};

export const ConnectorsAvatar = ({ connectorName, ...attrs }: ConnectorsAvatar) => {
  const icon = useMemo(() => CONNECTORS_META[connectorName]?.icon, [connectorName]);
  return <Avatar {...attrs} avatar={icon} />;
};

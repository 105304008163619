import { type CustomSeriesOptions, customSeriesDefaultOptions } from 'lightweight-charts';

export interface CustomHistogramSeriesOptions extends CustomSeriesOptions {
  color: string;
  radius: number;
}

export const customHistogramDefaultOptions: CustomHistogramSeriesOptions = {
  ...customSeriesDefaultOptions,
  color: '#FF63FA',
  radius: 2,
} as const;

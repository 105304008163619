import { cn } from '@/utils';
import { useMemo } from 'react';
import { CHAINS_META } from '../../constants';
import { Avatar, type AvatarProps } from './avatar';

type ChainAvatar = AvatarProps & {
  chainName: string;
};
export const ChainAvatar = ({ chainName, ...attrs }: ChainAvatar) => {
  const chianMeta = useMemo(() => CHAINS_META[chainName], [chainName]);
  return (
    <Avatar {...attrs} className={cn('size-5 rounded-full', attrs.className)} avatar={chianMeta?.icon} alt={chainName} />
  );
};

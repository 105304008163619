import { useSolanaBalance } from '@burve-front/react-sdk';
import {
  AddressView,
  Avatar,
  Button,
  ChainSolanaIcon,
  CommonPowerIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  NumberView,
  UserAvatar,
} from '@burve-front/react-ui';
import { useWallet } from '@solana/wallet-adapter-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-use';
import { formatUnits } from 'viem';
export const SolansUserMenu = () => {
  const { t } = useTranslation();
  const isMobile = useMedia('(max-width: 767px)');
  const { publicKey, disconnect, wallet } = useWallet();
  const address = useMemo(() => publicKey?.toString(), [publicKey]);
  const resultBalance = useSolanaBalance({ publicKey });
  const viewBalance = useMemo(
    () => (resultBalance.data ? formatUnits(BigInt(resultBalance.data.value), resultBalance.data.decimals) : '0'),
    [resultBalance.data],
  );
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={isMobile ? 'ghost' : 'outline'} size="sm" className="h-8 px-2 text-sm text-content-1 space-x-1">
          <UserAvatar address={address} className="size-5" />
          <dl className="hidden text-left leading-none lg:inline">
            <dt>
              <AddressView address={address} showTip={false} showCopy={false} />
            </dt>
            <dd className="text-[10px] leading-none opacity-60">{wallet?.adapter?.name}</dd>
          </dl>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem className="cursor-pointer space-x-1">
          <Avatar avatar={ChainSolanaIcon} className="size-4" />
          <span>{t('balance-label')}</span>
          <NumberView number={viewBalance} isLoading={resultBalance.isFetching} />
        </DropdownMenuItem>
        <DropdownMenuItem className="cursor-pointer space-x-1" onClick={() => disconnect()}>
          <CommonPowerIcon className="size-4" />
          <span>{t('disconnect')}</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

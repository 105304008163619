import useLocale from '@/hooks/use-locale';
import {
  Button,
  CommonCheckmarkIcon,
  CommonI18nIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@burve-front/react-ui';

//
export const SwitchLocale = () => {
  const { locale, locales, switchLocale } = useLocale();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" size="icon">
          <CommonI18nIcon className="size-5 fill-current" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {locales.map((item) => (
          <DropdownMenuItem
            key={`switch-locale-item-${item.name}`}
            onClick={() => switchLocale(item.lang)}
            className="h-10 cursor-pointer space-x-1">
            <span>{item.name}</span>
            {locale === item.lang && <CommonCheckmarkIcon className="size-3" />}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { candlestickData, chartData1, chartData2, histogramData, liquidityData, volumeData } from '@/apis/mocks/chart';
import { AreaChart, Button, KLineChart, LiquidityChart, VolumeChart } from '@burve-front/react-ui';
import { memo } from 'react';
import { Link } from 'wouter';

export const TheErrorPage = () => {
  return (
    <section className="w-full pt-[20%] text-center space-y-2">
      <div className="text-primary/60">404 Not Found !</div>
      <div>
        <Link to="/" className="text-sm text-primary hover:underline">
          Back Home
        </Link>
      </div>
      <div>
        <Button>Default</Button>
        <Button variant="primary">Primary</Button>
        <Button variant="secondary">Secondary</Button>
        <Button variant="outline">Outline</Button>
        <Button variant="accent">Accent</Button>
      </div>
      <div>
        <div className="flex flex-wrap items-center justify-center space-x-30">
          <dl>
            <dt>TVL</dt>
            <dd>
              <AreaChart
                series={[
                  {
                    data: chartData1,
                    options: {
                      lineColor: '#1AB6FF',
                      topColor: 'rgba(26, 182, 255, 0.2)',
                      bottomColor: 'rgba(26, 182, 255, 0)',
                    },
                  },
                  {
                    data: chartData2,
                    options: {
                      lineColor: '#FF63FA',
                      topColor: 'rgba(255, 99, 250, 0.2)',
                      bottomColor: 'rgba(253, 93, 155, 0)',
                    },
                  },
                ]}
                className="h-[187px] w-[500px]"
              />
            </dd>
          </dl>
          <dl>
            <dt>Volume</dt>
            <dd>
              <VolumeChart series={[{ data: volumeData }]} visibleNum={14} className="h-[187px] w-[500px]" />
            </dd>
          </dl>
          <dl>
            <dt>Price</dt>
            <dd>
              <AreaChart series={[{ data: chartData1 }]} className="h-[580px] w-[458px]" />
            </dd>
          </dl>
          <dl>
            <dt>Liquidity</dt>
            <dd>
              <LiquidityChart series={[{ data: liquidityData }]} className="h-[580px] w-[458px]" />
            </dd>
          </dl>
          <dl>
            <dt>KLine</dt>
            <dd>
              <KLineChart
                histogramSeries={{ data: histogramData }}
                candlestickSeries={{ data: candlestickData }}
                className="h-[580px] w-[458px]"
              />
            </dd>
          </dl>
          <dl>
            <dt>Add Liquidity</dt>
            <dd></dd>
          </dl>
        </div>
      </div>
    </section>
  );
};

export default memo(TheErrorPage);

import { routes } from '@/confs/route';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useTitle } from 'react-use';
import { useLocation } from 'wouter';

const useRouterTitle = () => {
  const [location] = useLocation();
  const { t } = useTranslation();
  const title = useMemo(
    () => `${t(routes.find((route) => route.path === location)?.name ?? '404')}-${t('title')}`,
    [location, t],
  );
  useTitle(title);
};

export default useRouterTitle;

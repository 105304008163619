import { cn } from '@/utils';
import { type Row, type RowData, type Table, flexRender } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import React, { type ReactNode } from 'react';
import { Empty } from '../empty';
import { BlockLoading } from '../loading/block-loading';
import { ColumnSort } from './column-sort';

export type VirtualTableProps<T extends RowData = RowData> = {
  className?: string;
  firstClassName?: string;
  headClassName?: string;
  trClassName?: string;
  table: Table<T>;
  isLoading?: boolean;
  empty?: ReactNode;
  onRowClick?: (row: Row<T>) => void;
};

export const VirtualTable = <T extends RowData>({
  className,
  headClassName,
  trClassName,
  table,
  isLoading,
  empty = 'No Data',
  onRowClick,
  firstClassName,
}: VirtualTableProps<T>) => {
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 50,
    getScrollElement: () => window.document.documentElement,
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
    debug: !import.meta.env.PROD,
  });
  const tbodyStyle = { height: `${rowVirtualizer.getTotalSize()}px` }; // bug to dom
  return (
    <table className={cn('rounded-lg bg-background-1/30 grid table-fixed text-sm', className)}>
      <thead
        className={cn(
          'sticky top-px z-10 h-12 w-full border rounded-t-lg grid bg-[#797D95]/15 backdrop-blur',
          headClassName,
        )}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={cn('w-full flex h-full items-center justify-between px-5 pr-15', trClassName)}>
            {headerGroup.headers.map((header, index) => {
              const size = header.column.getSize();
              return (
                <th
                  key={header.id}
                  style={{ width: size === 150 ? '100%' : `${size}px` }}
                  className={cn(
                    'text-content-1 font-normal flex items-center h-full',
                    index === 0 ? cn('min-w-[280px] justify-start', firstClassName) : 'justify-end',
                  )}>
                  <div
                    onClick={header.column.getToggleSortingHandler()}
                    className={header.column.getCanSort() ? 'flex cursor-pointer select-none space-x-2' : ''}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && <ColumnSort type={header.column.getIsSorted()} />}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody className="grid relative w-full" style={tbodyStyle}>
        {rowVirtualizer.getVirtualItems().map((virtualRow, index) => {
          const row = rows[virtualRow.index] as Row<T>;
          return (
            <tr
              ref={(node) => rowVirtualizer.measureElement(node)}
              key={row.id}
              data-index={virtualRow.index}
              onClick={() => !row.getCanExpand() && onRowClick?.(row)}
              style={{ transform: `translateY(${virtualRow.start}px)` }}
              className={cn(
                'flex absolute w-full h-15 w-full flex pl-5 pr-15 items-center border border-t-transparent',
                'hover:bg-[#9756AD]/20 cursor-pointer hover:border-[#9756AD] hover:border-solid',
                !row.getCanExpand() && row.getParentRows().length > 0 && 'bg-[#FFFFFF]/15 border-dashed',
                trClassName,
                index === rowVirtualizer.getVirtualItems().length - 1 && 'rounded-b-lg',
              )}>
              {row.getVisibleCells().map((cell, index) => {
                const size = cell.column.getSize();
                return (
                  <td
                    key={cell.id}
                    style={{ width: size === 150 ? '100%' : `${size}px` }}
                    className={cn(
                      'text-content font-normal flex items-center h-full',
                      index === 0 ? cn('min-w-[280px] text-start justify-start', firstClassName) : 'text-end justify-end',
                    )}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
      {isLoading && <BlockLoading />}
      {rows.length === 0 && <Empty>{empty}</Empty>}
    </table>
  );
};

import { ProjectLogoTextIcon } from '@burve-front/react-ui';
import { useTranslation } from 'react-i18next';

// Title
export const Title = () => {
  const { t } = useTranslation();
  return (
    <a href="/" aria-label={t('title')} className="inline-flex items-center whitespace-nowrap space-x-2">
      <ProjectLogoTextIcon className="h-8 w-[90px] fill-current md:h-[32px] md:w-[110px]" />
      <h1 className="hidden">{t('title')}</h1>
    </a>
  );
};

import { solana, solanaDevnet } from '@burve-front/core';
import { type Chain, base, bsc, sepolia } from 'viem/chains';
import {
  ChainBscIcon,
  ChainEthereumIcon,
  ChainSolanaIconUrl,
  CompanyCoinbaseIcon,
  CompanyInjectedIcon,
  CompanyMetamaskIcon,
  CompanyWalletconnectIcon,
} from '../assets';
import type { Icon } from '../types';

// chains meta
export const CHAINS_META: {
  [chainName: string]: {
    icon?: Icon;
  };
} = {
  [sepolia.name]: {
    icon: ChainEthereumIcon,
  },
  [solanaDevnet.name]: {
    icon: ChainSolanaIconUrl,
  },
  [bsc.name]: {
    icon: ChainBscIcon,
  },
  [base.name]: {
    icon: ChainBscIcon,
  },
  [solana.name]: {
    icon: ChainSolanaIconUrl,
  },
};

/// wallet meta
export const CONNECTORS_META: {
  [key: string]: {
    icon?: React.FunctionComponent<React.ComponentProps<'svg'> & { title?: string }> | string;
    isWalletConnect?: boolean;
    getDownLink?: (chain: Chain, link?: string) => string;
    getDeepLink?: (chain: Chain, link?: string) => string;
    getDisplayUri?: (chain: Chain, link?: string) => string | undefined;
  };
} = {
  injected: {
    icon: CompanyInjectedIcon,
  },
  metaMask: {
    icon: CompanyMetamaskIcon,
    getDownLink: () => 'https://metamask.io/download/',
    getDeepLink: () => `metamask://dapp/${window.location.host}${window.location.pathname}`,
  },
  coinbaseWalletSDK: {
    icon: CompanyCoinbaseIcon,
    getDownLink: () => `https://go.cb-w.com/dapp?cb_url=${window.location.href}`,
    getDeepLink: () => `cbwallet://dapp?url=${window.location.href}`,
  },
  walletConnect: {
    icon: CompanyWalletconnectIcon,
  },
};

import { cn } from '@/utils';
import { type RefObject, useEffect, useMemo } from 'react';
import { useLocation, useScroll, useWindowScroll } from 'react-use';
import { CommonDownIcon } from '../../assets';

type BackTopProps = {
  className?: string;
  showHeight?: number;
  minStep?: number;
  maxCount?: number;
  interval?: number;
  deviation?: number;
  parent?: RefObject<HTMLElement>;
};

// back top
export const BackTop = ({
  showHeight = 80,
  minStep = 80,
  maxCount = 5,
  interval = 60,
  deviation = 50,
  parent,
  ...attrs
}: BackTopProps) => {
  let history = 0;
  let top = 0;
  let start = Date.now();
  const scrollElement = parent ? parent.current : window.document.documentElement;
  const scroll = parent ? useScroll(parent) : useWindowScroll();
  const isShow = useMemo(() => scroll.y > showHeight, [scroll.y, showHeight]);
  const location = useLocation();

  const scrollToTop = () => {
    if (Date.now() - start >= interval && scrollElement && scroll?.y) {
      if (Math.abs(scrollElement.scrollTop - history) <= deviation) {
        if (scrollElement.scrollTop > 0) {
          const y = scrollElement.scrollTop - Math.min(scrollElement.scrollTop, top);
          scrollElement.scroll(scrollElement.scrollLeft, y);
          history = scrollElement.scrollTop;
          start = Date.now();
          window.requestAnimationFrame(scrollToTop);
        }
      }
    } else window.requestAnimationFrame(scrollToTop);
  };

  const gotTop = () => {
    if (!scroll?.y) return;
    history = scrollElement?.scrollTop || 0;
    top = Math.max(Math.floor(history / maxCount), minStep);
    scrollToTop();
  };
  useEffect(() => {
    if (location && scrollElement) {
      scrollElement.scrollTop = 0;
    }
  }, [location, scrollElement]);
  return (
    <aside
      className={cn(
        'fixed bottom-0 right-4 z-50',
        'transition-all duration-200',
        'h-10 w-10 cursor-pointer select-none flex items-center justify-center bg-background rounded-full border-1 outline-none md:right-8',
        'hover:-translate-y-10 hover:scale-105',
        attrs.className,
        isShow ? '-translate-y-20 opacity-90 md:-translate-y-8' : 'translate-y-20 opacity-0 md:translate-y-8',
      )}
      onClick={gotTop}
      {...attrs}>
      <CommonDownIcon className="size-6 rotate-180 fill-current" />
    </aside>
  );
};

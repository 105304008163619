import { Nav } from '@/components/nav';
import { BackTop } from '@burve-front/react-ui';
import { type PropsWithChildren } from 'react';
import { useMedia } from 'react-use';
import TheGeneralFooter from './footer';
import TheGeneralHeader from './header';

const TheGeneralLayout = ({ children }: PropsWithChildren) => {
  const isMobile = useMedia('(max-width: 767px)');
  return (
    <>
      <TheGeneralHeader />
      {children}
      <TheGeneralFooter />
      <BackTop />
      {isMobile && (
        <Nav
          type="row"
          className="border-gradient-primary fixed bottom-0 z-10 h-11 w-full flex items-center justify-between bg-[#120621]/50 px-[26px]"
        />
      )}
    </>
  );
};

export default TheGeneralLayout;

import { Token } from '@/types/token';
import { AddressView, Countdown, LargeSmallAvatar, NumberView } from '@burve-front/react-ui';

type TokenItemProps = { token: Token };
export const TokenItem = ({ token }: TokenItemProps) => {
  return (
    <a
      href={`/token/${token.address}`}
      target="_blank"
      className="h-12 flex cursor-pointer items-center justify-between whitespace-nowrap rounded-1 p-2 text-sm text-white hover:bg-[#9756AD]/20"
      rel="noreferrer">
      <div className="flex flex-1 items-center space-x-1">
        <LargeSmallAvatar large={token.logo} small={token.anchor.logo} />
        <div className="max-w-[270px] truncate whitespace-nowrap">{token.name}</div>
        <span>/</span>
        <span>{token.symbol}</span>
      </div>
      <AddressView address={token.address} showCopy={false} className="w-30" />
      <div className="w-22 text-right">
        {token.type === 'upcoming' ? (
          <Countdown className="text-warn" endDate={new Date(token.openTime * 1e3)} />
        ) : (
          <NumberView before="$" number={token.price} />
        )}
      </div>
    </a>
  );
};

import { ad } from '@/confs/app';
import { type Options, Splide } from 'react-splide-ts';
import { AdItem } from './ad-item';
import { useAdStore } from './ad-store';
import 'react-splide-ts/css/sea-green';
import { cn } from '@burve-front/react-ui';
import { useEffect, useRef } from 'react';

const options: Options = {
  perPage: 1,
  type: 'loop',
  autoplay: true,
  arrows: false,
  slideFocus: false,
  pagination: false,
  gap: 0,
  padding: 0,
};
export const AdBanner = () => {
  const isCloseAd = useAdStore((state) => state.isCloseAd);
  const splideRef = useRef<Splide>(null);
  useEffect(() => {
    if (isCloseAd) splideRef.current?.splide?.destroy();
    else splideRef.current?.splide?.refresh();
  }, [isCloseAd]);
  if (isCloseAd) return <></>;
  return (
    <Splide ref={splideRef} options={options} className={cn('h-9 w-full bg-[#250C43] !p-0')}>
      {ad.map((item) => (
        <AdItem key={`ad-${item.id}`} item={item} />
      ))}
    </Splide>
  );
};

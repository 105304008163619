import {
  Avatar,
  BlockLoading,
  Button,
  ChainEthereumIcon,
  CommonSearchIcon,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  Input,
  ProjectDownIcon,
} from '@burve-front/react-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
type TokenListProps = {
  isLoading?: boolean;
};
export const Select = ({ isLoading }: TokenListProps) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" className="p-2 h-10 min-w-[137px] text-content-1 justify-between">
          <span className="inline-flex items-center">
            <Avatar avatar={ChainEthereumIcon} className="mr-2" /> Ethereum
          </span>
          <ProjectDownIcon className="w-[10.62px] h-[6.16px]" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="start" className="min-w-[400px] p-0 border-gradient-primary">
        <DropdownMenuLabel className="h-8 w-full flex items-center border-b">
          <CommonSearchIcon className="mr-2 size-3.5" />
          <Input
            variant="ghost"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="size-full h-8 w-full cursor-pointer border-none text-xs"
            placeholder={t('search-project-name-token-symbol-contract-address')}
          />
        </DropdownMenuLabel>
        <table className="w-full">
          <thead>
            <tr className="w-full text-xs text-content-1 flex justify-between items-center p-2">
              <th className="flex-1 text-left">Token</th>
              <th className="text-left">Contract Address</th>
              {/* {isConnected && <th className="py-2 text-left">{t('balance')}</th>} */}
              {/* {searchList?.[searchList.length - 1]?.raisingToken && <th className="min-w-12"></th>} */}
            </tr>
          </thead>
          <tbody>
            <tr className="w-full text-xs text-content-1 flex justify-between items-center p-2">
              <td className="flex items-center">
                <Avatar avatar={ChainEthereumIcon} className="mr-2" />
                Ethereum
              </td>
              <td>0x7a250...3b9b</td>
            </tr>
          </tbody>
        </table>
        {isLoading && <BlockLoading />}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

import { CLOUDFLARE_ANALYTICS_TOKEN, TEST } from '@/confs/app';
import { useEffect, useRef } from 'react';

export const useCloudFlareAnalytics = () => {
  const ref = useRef(true);
  useEffect(() => {
    const scriptEl = document.createElement('script');
    if (!TEST && CLOUDFLARE_ANALYTICS_TOKEN && ref.current) {
      scriptEl.type = 'text/javascript';
      scriptEl.src = 'https://static.cloudflareinsights.com/beacon.min.js';
      scriptEl.setAttribute('data-cf-beacon', `{ "token": "${CLOUDFLARE_ANALYTICS_TOKEN}" }`);
      document.body.appendChild(scriptEl);
      ref.current = false;
    }
    return () => {
      if (!TEST) {
        document.body.removeChild(scriptEl);
      }
    };
  }, []);
};

export default useCloudFlareAnalytics;

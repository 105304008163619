import type { Icon } from '@/types';
import { cn } from '@/utils';
import { Avatar } from './avatar';
type LargeSmallAvatarProps = {
  large: Icon;
  small: Icon;
  className?: string;
  largeClassName?: string;
  smallClassName?: string;
};
export const LargeSmallAvatar = ({ large, small, className, largeClassName, smallClassName }: LargeSmallAvatarProps) => {
  return (
    <div className={cn('relative flex flex-shrink-0 items-center w-8', className)}>
      <Avatar avatar={large} className={cn('size-6', largeClassName)} />
      <Avatar avatar={small} className={cn('absolute right-0 size-4', smallClassName)} />
    </div>
  );
};

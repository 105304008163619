import type { Icon } from '@/types';
import { Avatar } from './avatar';

type PairAvatarProps = {
  one: Icon;
  two: Icon;
};
export const PairAvatar = ({ one, two }: PairAvatarProps) => {
  return (
    <div className="relative flex flex-shrink-0 items-center w-10">
      <Avatar avatar={one} className="size-6" />
      <Avatar avatar={two} className="absolute right-0 size-6" />
    </div>
  );
};

import { pools } from '@/apis/mocks';
import { PoolItem } from './pool-item';

export const TrendingPool = () => {
  const trendingPools = pools.slice(0, 10);
  return (
    <>
      {trendingPools.map((pool) => (
        <PoolItem key={pool.address} pool={pool} />
      ))}
    </>
  );
};
